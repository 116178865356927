import { Table } from 'antd'
import React from 'react'
import { formatRupiah } from '../../../Components/Global';
import { Link } from 'react-router-dom';

const TableOrder = ({ isFilteredData, isLoading }) => {

    const columns = [
        {
            key: "OrderCode",
            title: "Order Code",
            dataIndex: "OrderCode",
            fixed: "left",
            sorter: (a, b) => a.OrderCode.localeCompare(b.OrderCode),
            render: (text, record) => <Link to={`${record.AccountID}/${text}`} className="text-indigo-600 hover:text-indigo-400 font-medium">{text}</Link>,
        },
        {
            key: "OrderQuotation",
            title: "Order Quotation",
            dataIndex: "OrderQuotation",
            sorter: (a, b) => a.OrderQuotation.localeCompare(b.OrderQuotation),
        },
        {
            key: "ReceivedDate",
            title: "Received Date",
            dataIndex: "ReceivedDate",
            sorter: (a, b) => new Date(a.ReceivedDate) - new Date(b.ReceivedDate),
        },
        {
            key: "CustomerName",
            title: "Customer Name",
            dataIndex: "CustomerName",
            sorter: (a, b) => a.CustomerName.localeCompare(b.CustomerName),
        },
        {
            key: "SubmittedBy",
            title: "Submitted By",
            dataIndex: "SubmittedBy",
            sorter: (a, b) => a.SubmittedBy.localeCompare(b.SubmittedBy),
        },
        {
            key: "Priority",
            title: "Priority",
            dataIndex: "Priority",
            sorter: (a, b) => a.Priority.localeCompare(b.Priority),
        },
        {
            key: "PaymentStatus",
            title: "Payment Status",
            dataIndex: "PaymentStatus",
            sorter: (a, b) => a.PaymentStatus.localeCompare(b.PaymentStatus),
            render: (text) => {
                if (!text) {
                    return "-"
                }
                return text
            }
        },
        {
            key: "Total",
            title: "Total",
            dataIndex: "Total",
            sorter: (a, b) => a.Total.localeCompare(b.Total),
            render(text) {
                return (
                    <div className="flex justify-between">
                        <p>Rp</p> <p>{formatRupiah(text)}</p>
                    </div>
                )
            }
        }
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={isFilteredData}
                loading={isLoading}
                scroll={{
                    x: 1500,
                }}
            />
        </>
    )
}

export default TableOrder