import React, { useEffect, useState } from 'react'
import HeaderTitle from '../../Components/HeaderTitle'
import { Button, Form, Image, Input, message, Upload } from 'antd'
import { PlusOutlined, RollbackOutlined, UploadOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import TextArea from 'antd/es/input/TextArea'
import { PostNews } from '../../API/PostData'
import { getNews } from '../../API/GetData'
// import { getNews } from '../../API/GetData'

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const FormNews = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageData, setImageData] = useState(null);

    const [NewsCode, setNewsCode] = useState("");

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const res = await getNews();
                if (res.length > 0) {
                    const filterRes = res.filter(item => item.NewsCode.startsWith("NC"));
                    if (filterRes.length > 0) {
                        const lastCode = filterRes[filterRes.length - 1].NewsCode;
                        const nextNumber = parseInt(lastCode.substr(2)) + 1;
                        const nextCode = `NC${nextNumber.toString().padStart(3, "0")}`
                        setNewsCode(nextCode);
                    } else {
                        setNewsCode("NC001");
                    }
                } else {
                    setNewsCode("NC001");
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchNews();
    }, []);

    useEffect(() => {
        if (form && NewsCode) {
            form.setFieldsValue({
                NewsCode: NewsCode
            })
        }
    }, [NewsCode, form]);

    const handleChange = (info) => {
        const { file } = info;
        if (file && file.originFileObj) {
            const allowedExtensions = ['png', 'jpg', 'jpeg'];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (allowedExtensions.includes(fileExtension)) {
                getBase64(file.originFileObj, (base64) => {
                    setImageUrl(base64);
                });
                setImageData(file.originFileObj)
            } else {
                message.error('The uploaded file must be an image with the extension .png, .jpg, or .jpeg');
            }
        }
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            const payload = {
                ...values,
                ImageNews: imageData
            };
            // console.log(payload);
            const res = await PostNews(payload);
            message.success(res.data.message);
            navigate('/dashboard/news');
        } catch (error) {
            console.log(error);
            message.error(error.response.data.message);
        }
        setLoading(false);
    }

    const handleReset = () => {
        form.resetFields();
    }

    return (
        <>
            <div className="flex justify-between items-center">
                <HeaderTitle title="NEWS" />
                <Button icon={<RollbackOutlined />} onClick={() => navigate(-1)}>Back</Button>
            </div>

            <div className="bg-white p-4 rounded-md mt-4">
                <Form
                    name="formOrder"
                    layout="vertical"
                    onFinish={handleSubmit}
                    autoComplete="off"
                    form={form}
                >

                    {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-4"> */}

                    <div className="flex items-center justify-center pt-8">
                        <Form.Item>
                            <p className="text-center text-lg font-medium mb-2">Upload Image</p>
                            {imageUrl ? (
                                <div className="shadow-md">
                                    <Image
                                        src={imageUrl}
                                        alt="avatar"
                                        style={{ width: "140px", height: "auto", margin: "0", padding: "0" }}
                                    />
                                    <div className="flex w-full">
                                        <Upload
                                            showUploadList={false}
                                            onChange={handleChange}
                                            className="w-full bg-white hover:bg-gray-200 py-1 text-center"
                                        >
                                            <button type="button" className="w-32"><UploadOutlined /></button>
                                        </Upload>
                                    </div>
                                </div>
                            ) : (
                                <Upload
                                    name="file"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    onChange={handleChange}
                                >
                                    {uploadButton}
                                </Upload>
                            )}
                        </Form.Item>
                    </div>

                    <Form.Item
                        label="News Code"
                        name="NewsCode"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your News Code!',
                            },
                        ]}
                    >
                        <Input placeholder="News Code" />
                    </Form.Item>

                    <Form.Item
                        label="Title News"
                        name="TitleNews"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Title News!',
                            },
                        ]}
                    >
                        <Input placeholder="Title News" />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="Description"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Description!',
                            },
                        ]}
                    >
                        <TextArea rows={4} placeholder="Description" />
                    </Form.Item>
                    {/* </div> */}

                    <div className="flex justify-end gap-2">
                        <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
                        <Button htmlType="button" onClick={handleReset}>Reset</Button>
                    </div>

                </Form>
            </div>
        </>
    )
}

export default FormNews