import { Table } from 'antd'
import dayjs from 'dayjs';
import React from 'react'
import { Link } from 'react-router-dom';

const TableCOA = ({ isFilteredData, isLoading, isPage }) => {

    const columns = [
        {
            title: 'COA Code',
            dataIndex: 'COACode',
            key: 'COACode',
            fixed: 'left',
            sorter: (a, b) => a.COACode.localeCompare(b.COACode),
            render: (text, record) => <Link to={`${process.env.REACT_APP_BASEURL_DOC}/${record.PathName}`} target="_blank" className="text-indigo-600 hover:text-indigo-400 font-medium">{text}</Link>,
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            sorter: (a, b) => a.Status.localeCompare(b.Status),
        },
        {
            title: 'Publish Date',
            dataIndex: 'PublishDate',
            key: 'PublishDate',
            sorter: (a, b) => new Date(a.PublishDate) - new Date(b.PublishDate),
            render: (text) =>
                text ? <p>{dayjs(text).format("YYYY-MM-DD")}</p> : "-"
        },
        // {
        //     title: 'Approved By',
        //     dataIndex: 'ApprovedBy',
        //     key: 'ApprovedBy',
        // },
    ];

    if (!isPage) {
        const COACodeIndex = columns.findIndex(column => column.key === 'COACode');

        columns.splice(COACodeIndex + 1, 0,
            {
                title: 'Sample Code',
                dataIndex: 'SampleCode',
                key: 'SampleCode',
                sorter: (a, b) => a.SampleCode.localeCompare(b.SampleCode),
                render: (text,record) => <Link to={`/dashboard/menuorder/sample/${record.AccountID}/${text}`} className="text-indigo-600 hover:text-indigo-400 font-medium">{text}</Link>,
            },
            {
                title: 'Order Code',
                dataIndex: 'OrderCode',
                key: 'OrderCode',
                sorter: (a, b) => a.OrderCode.localeCompare(b.OrderCode),
                render: (text,record) => <Link to={`/dashboard/menuorder/order/${record.AccountID}/${text}`} className="text-indigo-600 hover:text-indigo-400 font-medium">{text}</Link>,
            },
            {
                title: 'Customer Name',
                dataIndex: 'CustomerName',
                key: 'CustomerName',
                sorter: (a, b) => a.CustomerName.localeCompare(b.CustomerName),
            }
        );
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={isFilteredData}
                loading={isLoading}
                scroll={{ x: 1000 }}
            />
        </>
    )
}

export default TableCOA