import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Layout, Menu, message, theme } from 'antd';
import {
    LineChartOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    PieChartOutlined,
    SnippetsOutlined,
    TagsOutlined,
    UsergroupAddOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import FloatButtonCS from './FloatButtonCS';
import { AuthData } from './Global';

const { Header, Content, Footer, Sider } = Layout;

const Layouts = ({ children }) => {
    const navigate = useNavigate();
    const userData = AuthData();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth <= 768);
    const [selectedKeys, setSelectedKeys] = useState(["1"]);
    const [openKeys, setOpenKeys] = useState();

    useEffect(() => {
        const handleResize = () => {
            setMobile(window.innerWidth <= 768);
        };
        if (mobile) {
            setCollapsed(true);
        } else {
            setCollapsed(false);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [mobile]);

    useEffect(() => {
        // Read the selected key and open keys from localStorage
        const storedSelectedKey = localStorage.getItem('selectedMenuKey');
        const storedOpenKeys = localStorage.getItem('openMenuKeys');

        if (storedSelectedKey) {
            setSelectedKeys([storedSelectedKey]);
        }

        if (storedOpenKeys) {
            setOpenKeys(JSON.parse(storedOpenKeys));
        }
    }, []);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    function getItem(label, key, icon, href, children) {
        return {
            key,
            icon,
            label: href ? <Link to={href}>{label}</Link> : label,
            children,
        };
    }

    const sidebarItems = [
        getItem("Dashboard", "1", <PieChartOutlined />, "/dashboard"),
        getItem("Menu Order", "MenuOrder", <SnippetsOutlined />, "", [
            getItem("Order", "2", "", "/dashboard/menuorder/order"),
            getItem("Sample", "3", "", "/dashboard/menuorder/sample"),
            getItem("COA", "4", "", "/dashboard/menuorder/COA"),
        ]),
        getItem("Invoice", "Invoice", <TagsOutlined />, "", [
            getItem("Invoice", "5", "", "/dashboard/menuinvoice/invoice"),
        ]),
        // getItem("Ticket Support", "TicketSupport", <ContainerOutlined />, "", [
        //     getItem("New Ticket Support", "6", "", "/dashboard/menuticket/newticketsupport"),
        //     getItem("Ticket Support", "7", "", "/dashboard/menuticket/ticketsupport"),
        // ]),
        getItem("Analytic", "8", <LineChartOutlined />, "/dashboard/analytic"),
        (userData.Role === "SuperAdmin" || userData.Role === "Admin") &&
        getItem("Customer Account", "9", <UsergroupAddOutlined />, "/dashboard/account"),
        (userData.Role === "SuperAdmin") &&
        getItem("Admin Account", "10", <UserOutlined />, "/dashboard/account_admin"),
        (userData.Role === "SuperAdmin") &&
        getItem("News", "11", <SnippetsOutlined />, "/dashboard/news"),

    ];

    const siderbarItemsCust = [
        getItem("Dashboard", "1", <PieChartOutlined />, "/customer/dashboard"),
        getItem("Analytic", "2", <LineChartOutlined />, "/customer/analytic"),
    ];

    const itemsToRender = location.pathname.startsWith('/customer') ? siderbarItemsCust : sidebarItems;


    const handleMenuClick = (e) => {
        // Save the clicked menu key to localStorage
        if (mobile) {
            setCollapsed(true);
        }
        localStorage.setItem('selectedMenuKey', e.key);
        setSelectedKeys([e.key]);
    };

    const handleOpenChange = (keys) => {
        // Save the open keys to localStorage
        localStorage.setItem('openMenuKeys', JSON.stringify(keys));
        setOpenKeys(keys);
    };

    const handleSignOut = () => {
        Cookies.remove('auth_token');
        localStorage.clear();
        message.success('You have successfully signed out.');
        navigate('/');
        // window.location.reload();
    };


    const menuItems = [
        {
            key: 'account',
            icon: <UserOutlined />,
            label: <Link to={`/dashboard/profile`}>Profile Account</Link>,
        },
        {
            type: 'divider',
        },
        {
            key: 'logout',
            icon: <LogoutOutlined />,
            label: <button onClick={handleSignOut}>Sign Out</button>,
        },
    ];

    return (
        <Layout style={{ minHeight: "100vh" }}>
            {!mobile || (mobile && !collapsed) ? (
                <Sider
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    width={250}
                    style={{
                        overflow: "auto",
                        height: "100vh",
                        position: "fixed",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        background: "#001529",
                        color: "#fff"
                    }}
                >
                    <div className="demo-logo-vertical" />
                    <div className="w-full px-4 py-2 flex items-center justify-center">
                        {!collapsed ? (
                            <p className="text-4xl font-bold">SA-CRMS</p>
                        ) : (
                            <p className="text-xl font-bold">SA</p>
                        )}
                    </div>
                    <Menu
                        theme="dark"
                        mode="inline"
                        selectedKeys={selectedKeys}
                        openKeys={openKeys}
                        items={itemsToRender}
                        onClick={handleMenuClick}
                        onOpenChange={handleOpenChange}
                    />
                </Sider>
            ) : null}
            <Layout
                style={{
                    marginLeft: mobile && collapsed ? 0 : (collapsed ? 80 : 250),
                    transition: "margin-left 0.2s",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Header
                    style={{
                        padding: 0,
                        background: colorBgContainer,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: "16px",
                            width: 42,
                            height: 42,
                        }}
                    />

                    <Dropdown menu={{ items: menuItems }} trigger={['click']}>
                        <div className="flex items-center justify-center gap-2 mr-5 cursor-pointer">
                            <div className="border rounded-full p-2 flex items-center justify-center">
                                <UserOutlined style={{ fontSize: '16px' }} />
                            </div>
                            <p>{userData && userData.Username}</p>
                        </div>
                    </Dropdown>
                </Header>

                <Content
                    style={{
                        margin: "24px 16px",
                        overflow: "initial",
                        flex: 1,
                    }}
                >
                    {children}
                    <FloatButtonCS />
                </Content>
                {/* <Footer
                    style={{
                        textAlign: "center",
                    }}
                >
                    Ant Design ©{new Date().getFullYear()} Created by Ant UED
                </Footer> */}
            </Layout>
        </Layout>
    );
};

export default Layouts;
