import React, { useState } from 'react'
import OrderStatus from './OrderStatus'
import OutstandingInvoice from './OutstandingInvoice'
// import OrderTracker from './OrderTracker'
// import TicketSupport from './TicketSupport'
import CarouselDashboard from './Carousel'
import FilterDate from './FilterDate'

const Dashboard = () => {

  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");

  return (
    <>
      <CarouselDashboard />
      <FilterDate
        onDate1={(values) => setDate1(values)}
        onDate2={(values) => setDate2(values)}
      />
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="flex-1 flex flex-col gap-4">
          <OrderStatus
            onDate1={date1}
            onDate2={date2}
          />
        </div>
        <div className="flex-1 flex flex-col gap-4">
          <OutstandingInvoice
            onDate1={date1}
            onDate2={date2}
          />
          {/* <OrderTracker /> */}
          {/* <TicketSupport /> */}
        </div>
      </div>
    </>
  )
}

export default Dashboard