import React from 'react';
import Chart from 'react-google-charts';

const ChartSMG = ({ dataSource }) => {    

    const data = dataSource && dataSource.length > 0
        ? [
            ['Customer Name', 'Previous Month', 'Current Month', 'Growth Rate'], // Column names
            // [datas.CustomerName, Number(datas.PreviousMonth), Number(datas.CurrentMonth), Number(datas.GrowthRate)], // Data row
            ...dataSource.map((item) => [
                item.CustomerName, 
                Number(item.PreviousMonth), 
                Number(item.CurrentMonth), 
                Number(item.GrowthRate)
            ])
        ]
        : [['Customer Name', 'Previous Month', 'Current Month', 'Growth Rate'], ["No Data", 0, 0, 0]]; // Default data

    return (
        <div style={{ width: '100%', textAlign: 'center' }}>
            {/* <h2>Sales Growth Chart</h2> */}
            <div style={{ width: '100%', height: '400px' }}>
                <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="100%"
                    data={data}
                    options={{
                        title: 'Sales Growth', // Title inside the chart (optional)
                        // hAxis: { title: 'Customer Name' },
                        // vAxis: { title: 'Value' },
                        legend: { position: 'top' },
                    }}
                />
            </div>
        </div>
    );
};

export default ChartSMG;
