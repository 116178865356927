import axios from "axios"

const baseURL = process.env.REACT_APP_BASEURL;

export const UpdateInvoice = async (AccountID, InvoiceNo, Data) => {
    const response = await axios.put(`${baseURL}/dashboard/update/invoice?AccountID=${AccountID}&InvoiceNo=${InvoiceNo}`, Data);
    return response;
}

export const UpdateProfile = async (Data) => {
    const response = await axios.put(`${baseURL}/dashboard/update/profile`, Data);
    return response;
}

export const UpdateAccoutnAdmin = async (Data) => {
    const response = await axios.put(`${baseURL}/dashboard/update/account_admin`, Data);
    return response;
}

export const UpdateNews = async (NewsCode, Data) => {
    const response = await axios.put(`${baseURL}/dashboard/update/news?NewsCode=${NewsCode}`, Data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    return response
}

export const UpdateCustomerAccount = async (Data) => {
    const response = await axios.put(`${baseURL}/dashboard/update/customer_account`, Data);
    return response
}