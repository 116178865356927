import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import FormDetail from './FormDetail';
import OrderTab from '../../Order/DetailOrder/OrderTab';
import Customer from './Customer';
import { getOrder, getSample } from '../../../../API/GetData';

const DetailSample = () => {
  const params = useParams();
  const { accountid, code } = params;
  const [dataSource, setDataSource] = useState([]);
  const [dataOrder, setDataOrder] = useState("");

  const fetchData = async () => {
    try {
      const res = await getSample(accountid, code);
      setDataSource(res);
    } catch (error) {
      console.log(error);
    }
  };  

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchDataOrder = async () => {
      try {

        const orderCode = dataSource && dataSource.Sample ? dataSource.Sample.OrderCode : '';

        if (orderCode) {
          const response = await getOrder(accountid, orderCode);
          setDataOrder(response[0].Order);

        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataOrder();
  }, [accountid, dataSource]);

  const items = [
    {
      key: '1',
      label: 'Sample',
      children: <FormDetail onDataSource={dataSource} />,
    },
    {
      key: '2',
      label: 'Order',
      children: <OrderTab onDataSource={dataOrder} />,
    },
    {
      key: '3',
      label: 'Customer',
      children: <Customer onDataSource={dataSource} />,
    },
  ];

  return (
    <div className="bg-white p-4 rounded-md">
      <p className="text-xl pb-2">{code}</p>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  )
}

export default DetailSample