import axios from "axios"
import dayjs from "dayjs";
import { AuthData } from "../Components/Global";

const baseURL = process.env.REACT_APP_BASEURL;
// const userData = AuthData();
// const userAccountID =
//     userData &&
//         (userData.Role === "user" || userData.Role === "User") ?
//         userData.AccountID : ''

const awalBulan = dayjs().startOf('year').format('YYYY-MM-DD');
const akhirBulan = dayjs().endOf('year').format('YYYY-MM-DD');

// GET ORDER
export const getOrderView = async (Date1, Date2, OrderCode) => {
    const userData = AuthData();
    const accountid = userData.Role === "User" ? userData.AccountID : '';
    const response = await axios.get(
        `${baseURL}/dashboard/get/order_view?AccountId=${accountid}&OrderCode=${OrderCode ? OrderCode : ''}&Date1=${Date1 ? Date1 : awalBulan}&Date2=${Date2 ? Date2 : akhirBulan}`
    );

    // Mengurutkan hasil berdasarkan OrderCode secara descending (DESC)
    const sortedData = response.data.sort((a, b) => b.OrderCode.localeCompare(a.OrderCode));

    return sortedData.map((row, index) => ({ ...row, key: index + 1 }));
};


export const getOrder = async (AccountID, OrderCode) => {
    const response = await axios.get(`${baseURL}/dashboard/get/order/detail?AccountId=${AccountID}&OrderCode=${OrderCode}`)
    return response.data.map((row, index) => ({ ...row, key: index + 1 }))
}

export const getOrders = async () => {
    const response = await axios.get(`${baseURL}/dashboard/get/orders`);
    const sortedData = response.data.sort((a, b) => a.OrderCode.localeCompare(b.OrderCode));
    return sortedData.map((row, index) => ({ ...row, key: index + 1 }));
}

// GET SAMPLE
export const getSampleView = async (Date1, Date2) => {
    const userData = AuthData();
    const accountid = userData.Role === "User" ? userData.AccountID : ''
    const response = await axios.get(`${baseURL}/dashboard/get/sample_view?AccountId=${accountid}&SampleCode=&Date1=${Date1 ? Date1 : awalBulan}&Date2=${Date2 ? Date2 : akhirBulan}`)
    return response.data.map((row, index) => ({ ...row, key: index + 1 }))
}

export const getSample = async (AccountID, SampleCode) => {
    const response = await axios.get(`${baseURL}/dashboard/get/sample?AccountId=${AccountID}&SampleCode=${SampleCode}`)
    return response.data
}

export const getSamples = async (AccountID, SampleCode) => {
    const response = await axios.get(`${baseURL}/dashboard/get/samples${AccountID ? `?AccountID=${AccountID}` : ''}${SampleCode ? `&SampleCode=${SampleCode}` : ''} `)
    return response.data;
}

// GET COA
export const getCOAView = async (Date1, Date2) => {
    const userData = AuthData();
    const accountid = userData.Role === "User" ? userData.AccountID : ''
    const response = await axios.get(`${baseURL}/dashboard/get/coa_view?AccountId=${accountid}&COACode=&Date1=${Date1 ? Date1 : awalBulan}&Date2=${Date2 ? Date2 : akhirBulan}`)
    return response.data.map((row, index) => ({ ...row, key: index + 1 }))
}

// GET CUSTOMER
export const getCustomerDetail = async (AccountID) => {
    const response = await axios.get(`${baseURL}/dashboard/get/customer/detail${AccountID ? `?AccountID=${AccountID}` : ''}`);
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getCustomers = async () => {
    const response = await axios.get(`${baseURL}/dashboard/get/customers`);
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

// GET INVOICE
export const getInvoiceView = async (Date1, Date2) => {
    const userData = AuthData();
    const accountid = userData.Role === "User" ? userData.AccountID : ''
    const response = await axios.get(`${baseURL}/dashboard/get/invoice_view?AccountId=${accountid}&InvoiceNo=&Date1=${Date1 ? Date1 : awalBulan}&Date2=${Date2 ? Date2 : akhirBulan}`)
    const sortedData = response.data.sort((a, b) => a.InvoiceNo.localeCompare(b.InvoiceNo));
    
    return sortedData.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getInvoice = async (AccountID, InvoiceNo) => {
    const response = await axios.get(`${baseURL}/dashboard/get/invoice?AccountId=${AccountID}&InvoiceNo=${InvoiceNo}`)
    return response.data.map((row, index) => ({ ...row, key: index + 1 }))
}

export const getInvoices = async () => {
    const response = await axios.get(`${baseURL}/dashboard/get/invoices`);
    const sortedData = response.data.sort((a, b) => a.InvoiceNo.localeCompare(b.InvoiceNo));
    return sortedData
}

export const getOrderDocs = async (AccountID, OrderCode) => {
    const response = await axios.get(`${baseURL}/dashboard/get/order/docs?AccountID=${AccountID}&OrderCode=${OrderCode}`);
    return response.data;
}

export const getCustomerAccount = async () => {
    const response = await axios.get(`${baseURL}/dashboard/get/customer_account`);
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getAccountAdmin = async () => {
    const response = await axios.get(`${baseURL}/dashboard/get/account_admin`);
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getNews = async () => {
    const response = await axios.get(`${baseURL}/dashboard/get/news`);
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}

export const getUsers = async () => {
    const response = await axios.get(`${baseURL}/dashboard/get/users`);
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}


//ANALYTIC
export const getSalesMonthGrowth = async (AccountID, Date) => {
    const userData = AuthData();
    const accountid = userData.Role === "User" ? userData.AccountID : ''
    const response = await axios.get(`${baseURL}/dashboard/get/SalesMonthGrowth?AccountID=${accountid ? accountid : AccountID}&Date=${Date}`)
    return response.data
}

export const getSalesYTD = async (AccountID, Date) => {
    const userData = AuthData();
    const accountid = userData.Role === "User" ? userData.AccountID : ''
    const response = await axios.get(`${baseURL}/dashboard/get/SalesYTD?AccountID=${accountid ? accountid : AccountID}&Date=${Date}`)
    return response.data
}

export const getTOPYearSales = async (AccountID, Date) => {
    const userData = AuthData();
    const accountid = userData.Role === "User" ? userData.AccountID : ''
    const response = await axios.get(`${baseURL}/dashboard/get/TOPYearSales?AccountID=${accountid ? accountid : AccountID}&Date=${Date}`)
    return response.data
}