import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getInvoice, getOrder, getOrderDocs } from '../../../API/GetData';
import { Button, DatePicker, Form, Input, message, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import InvoiceOrder from './InvoiceOrder';
import { AuthData } from '../../../Components/Global';
import { UpdateInvoice } from '../../../API/UpdateData';
import UploadFile from '../../MenuOrder/Order/Form/UploadFile';
import { SendEmail, UploadOrderDocs } from '../../../API/PostData';

const emailTemplate = (values, data) => `
    <!DOCTYPE html>
    <html lang="id">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Process Order</title>
       <style>
                body { font-family: Arial, sans-serif; background-color: #f4f4f4; }
                .email-container { max-width: 600px; margin: 0 auto; padding: 20px; background-color: #fff; }
                .header { text-align: center; }
                .content { color: #333; }
                .order-info { font-weight: bold; color: #312E81; }
                .btn-download { background-color: #312E81; color: #fff; padding: 10px 20px; text-decoration: none; border-radius: 5px; cursor: pointer; }
                .footer { text-align: center; color: #777; }
            </style>
    </head>
    <body>
        <div class="email-container">
            <div class="header">
                <h2>Halo, ${data.CustomerName}</h2>
            </div>
            <div class="content">
                <p>Status Invoice Anda dengan No.${data.InvoiceNo}, telah di perbarui menjadi <b>${values.PaymentStatus}</b></p>
                
                <p>Untuk mengetahui lebih detail informasi Invoice, Anda dapat mengunjungi
                <a href="https://sainsanalitikaprima.co.id/" target="_blank">
                Customer Portal Kami
                </a>
                .</p>
                ${values.PaymentStatus === 'PAID' ? 
                    '<p>Terima kasih telah memilih LAB kami untuk melakukan pengujian pada sample Anda</p>'
                : ''
                }
            </div>
            <div class="footer">
                <p>Salam,<br>Sains Analitika Prima Lab</p>
            </div>
        </div>
    </body>
    </html>
`;

const DetailInvoice = () => {
    const { accountid, code } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState('');
    const [files, setFiles] = useState(null);
    const [dataOrder, setDataOrder] = useState(null);
    const Order = dataOrder ? dataOrder : [];

    console.log(data);


    const userData = AuthData();
    const Status = userData.Role === "Admin" || userData.Role === "SuperAdmin" ? false : true;

    const [docsINV, setDocsINV] = useState([]);

    const [docs, setDocs] = useState(null);

    // DOCS
    useEffect(() => {
        const fetchDocs = async () => {
            try {
                const res = await getOrderDocs(accountid, code);
                setDocs(res[0]);
            } catch (error) {
                console.log(error);
            }
        }
        fetchDocs();
    }, [accountid, code]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getInvoice(accountid, code);
                const payload = {
                    ...res[0],
                    InvoiceDate: dayjs(res[0].InvoiceDate),
                    InvoiceSentDate: dayjs(res[0].InvoiceSentDate),
                    PaymentDate: res[0].PaymentDate ? dayjs(res[0].PaymentDate) : ''
                }
                setData(payload);
                const resOrder = await getOrder(payload.AccountID, payload.OrderCode);
                setDataOrder(resOrder[0].Order);


                const resDocs = await getOrderDocs(accountid, code);
                setDocsINV(resDocs);


            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [accountid, code]);


    const handleSubmit = async (values) => {
        try {
            setLoading(true);

            // Validate file type if user uploads a file
            if (files) {
                const [firstKey, firstValue] = files.entries().next().value;
                if (firstValue.type !== 'application/pdf') {
                    message.error('Only PDF files are allowed.');
                    setLoading(false);
                    return;
                }
            }

            const payload = {
                ...values,
                PaymentStatus: userData.Role === "Admin" || userData.Role === "SuperAdmin" ? values.PaymentStatus : "PENDING REVIEW"
            };
            const res = await UpdateInvoice(accountid, values.InvoiceNo, payload);

            if (res.status === 200 && userData.Role === "User") {
                if (files) {
                    const [firstKey, firstValue] = files.entries().next().value;

                    const filePayload = new FormData();
                    filePayload.append('AccountID', userData.AccountID);
                    filePayload.append('OrderCode', values.InvoiceNo);
                    filePayload.append('DocCode', values.InvoiceNo);
                    filePayload.append('DocType', "INV");
                    filePayload.append(firstKey, firstValue);

                    const fileResponse = await UploadOrderDocs(filePayload);
                    console.log('File upload response:', fileResponse);
                }

            }
            if (res) {
                const payloadEmail = {
                    email: data ? data.EmailContact.toString() : "aditiaramadhan301101@gmail.com",
                    subject: `Status Invoice dengan No.${values.InvoiceNo}, Sains Analitika Prima Lab`,
                    message: "",
                    html: emailTemplate(values, data),
                }
                const resEmail = await SendEmail(payloadEmail);
            }

            message.success(res.data.message);
            // navigate('/dashboard/menuinvoice/invoice');
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        const payload = {
            ...data,
            // InvoiceDate: dayjs(data.InvoiceDate),
            // InvoiceSentDate: dayjs(data.InvoiceSentDate),
            // PaymentDate: data.PaymentDate ? dayjs(data.PaymentDate) : '',
        }
        form.setFieldsValue(payload)
    }, [data, form]);


    return (
        <div>
            <h1 className="text-2xl">Upload Invoice</h1>

            <div className="bg-white p-4 w-full mt-4 rounded-md">
                <p className="border-b-2 pb-2 text-lg font-medium">Invoice Data</p>

                <Form
                    name="filterForm"
                    layout="vertical"
                    onFinish={handleSubmit}
                    autoComplete="off"
                    form={form}
                    className="mt-4 w-full"
                >

                    <div className="mx-2 my-2">
                        <p className="border-b-2 pb-2 text-xl mb-4">Basic Information</p>

                        <Form.Item
                            label="InvoiceNo"
                            name="InvoiceNo"
                        >
                            <Input placeholder="InvoiceNo" disabled />
                        </Form.Item>

                        <Form.Item
                            label="Tax Faktur No"
                            name="TaxFakturNo"
                        >
                            <Input placeholder="Tax Faktur No" disabled />
                        </Form.Item>

                        <Form.Item
                            label="Invoice Date"
                            name="InvoiceDate"
                        >
                            <DatePicker className="w-full" disabled />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="Description"
                        >

                            <TextArea rows={4} placeholder="Description" disabled />
                        </Form.Item>
                    </div>

                    <div className="ml-2 my-2">
                        <p className="border-b-2 pb-2 text-xl mb-4">Billing Information</p>

                        <Form.Item
                            label="Customer"
                            name="CustomerName"
                        >
                            <Input placeholder="Customer" disabled />
                        </Form.Item>

                        <Form.Item
                            label="Contact"
                            name="ContactPerson"
                        >
                            <Input placeholder="Contact" disabled />
                        </Form.Item>

                        <Form.Item
                            label="Address"
                            name="Address"
                        >
                            <TextArea rows={2} placeholder="Address" disabled />
                        </Form.Item>

                        <Form.Item
                            label="Invoice Sent Date"
                            name="InvoiceSentDate"
                        >
                            <DatePicker className="w-full" disabled />
                        </Form.Item>

                        <Form.Item
                            label="Email Address"
                            name="EmailContact"
                        >
                            <Input placeholder="Email Address" disabled />
                        </Form.Item>

                    </div>

                    <div className="ml-2 my-2">
                        <p className="border-b-2 pb-2 text-xl mb-4">Payment Information</p>

                        <Form.Item
                            label="Payment Status"
                            name="PaymentStatus"
                        >
                            <Select
                                placeholder="All Invoice Status"
                                options={[
                                    {
                                        value: 'UNPAID',
                                        label: 'UNPAID',
                                    },
                                    {
                                        value: 'PENDING REVIEW',
                                        label: 'PENDING REVIEW',
                                    },
                                    {
                                        value: 'REVISION NEEDED',
                                        label: 'REVISION NEEDED',
                                    },
                                    {
                                        value: 'PAID',
                                        label: 'PAID',
                                    },
                                    {
                                        value: 'REJECT',
                                        label: 'REJECT',
                                    },
                                ]}
                                disabled={Status}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Payment Date"
                            name="PaymentDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Payment Date!',
                                },
                            ]}
                        >
                            <DatePicker className="w-full" placeholder="Payment Date" />
                        </Form.Item>

                        <Form.Item
                            label="Upload Payment"
                            name="Upload Payment"
                        >
                            {userData.Role === "Admin" || userData.Role === "SuperAdmin" ? (
                                docs && docs.PathName ? (
                                    <Link
                                        to={`${process.env.REACT_APP_BASEURL_DOC}/${docs.PathName}`}
                                        target="_blank"
                                    >
                                        <Button>Show Payment</Button>
                                    </Link>
                                ) : (
                                    <Button disabled>Show Payment</Button>
                                )

                            ) : (
                                <div className="flex flex-col gap-4">
                                    <UploadFile onFiles={(values) => setFiles(values)} />
                                    {!files && docsINV.map((item, index) => (
                                        <div key={index}>
                                            <Link to={`${process.env.REACT_APP_BASEURL_DOC}/${item.PathName}`} target="_blank" className="ml-4">● {item.FileName}</Link>
                                        </div>
                                    ))}

                                </div>

                            )}
                        </Form.Item>

                        <InvoiceOrder dataSource={Order} />

                        <div className="flex justify-end gap-2 mt-4 mb-2">
                            <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
                            <Link to={'/dashboard/menuinvoice/invoice/'}>
                                <Button type="primary" htmlType="submit" danger>Cancel</Button>
                            </Link>
                        </div>

                    </div>

                </Form>


            </div>

        </div >
    )
}

export default DetailInvoice