import { Button, DatePicker, Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react'
import FormTableWorkSheet from './FormTableWorkSheet';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { RollbackOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

const DetailFormSample = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paramsSC = searchParams.get('sampleCode');

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [DataWorkSheet, setDataWorkSheet] = useState([]);
    const [dataEditWS, setDataEditWS] = useState([]);
    let res = ""
    useEffect(() => {
        window.scrollTo(0, 0);
        if (form && paramsSC) {
            const storedData = localStorage.getItem('formSampleData');
            const dataSample = JSON.parse(storedData);
            const filterData = dataSample.filter((item) => item.SampleCode === paramsSC);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            res = filterData[0];
            // console.log("Sample", res);

            const payload = {
                ...res,
                ReceivedDate: dayjs(res.ReceivedDate),
                COADueDate: dayjs(res.COADueDate),
            }
            form.setFieldsValue(payload);
            setDataEditWS(res.sampleWS);

        }
    }, [form, paramsSC]);

    useEffect(() => {
        const storedData = localStorage.getItem('formSampleData');
        let sampleCode = '';
        if (storedData) {
            const dataSample = JSON.parse(storedData);
            const SCData = dataSample.filter(
                (item) => item.SampleCode && item.SampleCode.startsWith("SC")
            );
            if (SCData.length > 0) {
                const lastCode = dataSample[dataSample.length - 1].SampleCode;
                const nextCode = parseInt(lastCode.substr(2)) + 1;
                sampleCode = `SC${nextCode.toString().padStart(3, "0")}`;
            }
        } else {
            sampleCode = "SC001"
        }
        form.setFieldsValue({
            SampleCode: sampleCode
        })
    }, [form]);



    const handleSubmit = async (values) => {
        const payload = {
            ...values,
            sampleWS: DataWorkSheet,
            ResultSummary: "Pass",
            VolQty: 10,
        };

        const storedData = localStorage.getItem('formSampleData');
        let existingData = [];

        if (storedData) {
            try {
                existingData = JSON.parse(storedData);
            } catch (error) {
                console.error('Error parsing stored data:', error);
            }
        }

        const existingIndex = existingData.findIndex(item => item.SampleCode === payload.SampleCode);

        if (existingIndex !== -1) {
            existingData[existingIndex] = payload;
        } else {
            existingData.push(payload);
        }

        localStorage.setItem('formSampleData', JSON.stringify(existingData));

        form.resetFields();
        localStorage.removeItem('dataExcel');
        navigate('/dashboard/menuorder/order/form');
    };

    const handleCancel = () => {
        if (paramsSC) {
            const payload = {
                ...res,
                ReceivedDate: dayjs(res.ReceivedDate),
                COADueDate: dayjs(res.COADueDate),
            }
            form.setFieldsValue(payload);
        } else {
            form.resetFields();
        }
    };

    const handleModalSubmit = () => {
        form.submit();
    };

    return (
        <>
            <div className="flex justify-between items-center">
                <div className="flex flex-col">
                    <h1 className="text-3xl font-bold">FORM SAMPLE</h1>
                    <p className="text-lg">create a sample</p>
                </div>
                <Button icon={<RollbackOutlined />} onClick={() => navigate(-1)}>Back</Button>
            </div>

            <div className="bg-white p-4 rounded-md mt-4">
                <Form
                    centered
                    layout="vertical"
                    name="formSample"
                    onFinish={handleSubmit}
                    autoComplete="off"
                    form={form}
                >
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <Form.Item
                            label="Sample Code"
                            name="SampleCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Sample Code!',
                                },
                            ]}
                        >
                            <Input placeholder="Sample Code" />
                        </Form.Item>

                        <Form.Item
                            label="Sample Name"
                            name="SampleName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Sample Name!',
                                },
                            ]}
                        >
                            <Input placeholder="Sample Name" />
                        </Form.Item>
                    </div>

                    <Form.Item
                        label="Description"
                        name="Description"
                        layout="vertical"
                    >
                        <TextArea rows={4} placeholder="Desctiption" />
                    </Form.Item>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                        <Form.Item
                            label="Receive Date"
                            name="ReceivedDate"
                            className="w-full"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Receive Date!',
                                },
                            ]}
                        >
                            <DatePicker className="w-full" />
                        </Form.Item>

                        <Form.Item
                            label="COA Release Due Date"
                            name="COADueDate"
                            className="w-full"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your COA Release Dude Date!',
                                },
                            ]}
                        >
                            <DatePicker className="w-full" />
                        </Form.Item>
                    </div>

                    <Form.Item
                        label="Result Summary"
                        name="ResultSummary"
                        layout="vertical"
                    >
                        <TextArea rows={4} placeholder="Result Summary" />
                    </Form.Item>

                </Form>
                {/* FORM TABLE WorkSheet */}
                <FormTableWorkSheet onSaveData={(values) => setDataWorkSheet(values)} onEditData={dataEditWS} />

                <div className="flex flex-row justify-end gap-2">
                    <Button type="primary" onClick={handleModalSubmit}>Submit</Button>
                    <Button htmlType="reset" onClick={handleCancel}>Reset</Button>
                </div>
            </div>

        </>
    )
}

export default DetailFormSample