import { Button, DatePicker, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { getCOAView } from '../../../API/GetData';
import TableCOA from './TableCOA';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { AuthData } from '../../../Components/Global';

const { RangePicker } = DatePicker;

const SubCOA = () => {
  const [filterForm] = Form.useForm();
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userData = AuthData();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await getCOAView();
      setData(res);
      setFilteredData(res);
    } catch (error) {
      console.log();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [])


  const onFilterData = (values) => {

    try {
      setIsLoading(true);

      let filtered = data;

      if (values.COACode) {
        filtered = filtered.filter(item =>
          item.COACode.toLowerCase().includes(values.COACode.toLowerCase())
        );
      }

      if (values.SampleCode) {
        filtered = filtered.filter(item =>
          item.SampleCode.toLowerCase().includes(values.SampleCode.toLowerCase())
        );
      }

      if (values.OrderCode) {
        filtered = filtered.filter(item =>
          item.OrderCode.toLowerCase().includes(values.OrderCode.toLowerCase())
        );
      }

      if (values.COAStatus && values.COAStatus !== 'AllCOAStatus') {
        filtered = filtered.filter(item => item.Status === values.COAStatus);
      }

      if (values.PublishDate && values.PublishDate.length === 2) {
        const [start, end] = values.PublishDate;
        filtered = filtered.filter(item =>
          new Date(item.PublishDate) >= start && new Date(item.PublishDate) <= end
        );
      }

      setFilteredData(filtered);

    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onResetFilter = () => {
    filterForm.resetFields();
    setFilteredData(data);
  };

  return (
    <div className="bg-white p-4 rounded-md">
      <h1 className="w-full border-b-2 text-lg pb-2">List of Certificate of Analysis</h1>

      <Form
        name="filterForm"
        layout="vertical"
        onFinish={onFilterData}
        autoComplete="off"
        form={filterForm}
        className="relative border-2 rounded-md p-4 my-4"
      >
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <Form.Item
            label="Search by Certificate Code"
            name="COACode"
          >
            <Input placeholder="Search by Certificate Code" />
          </Form.Item>

          <Form.Item
            label="Search by Sample Code"
            name="SampleCode"
          >
            <Input placeholder="Search by Sample Code" />
          </Form.Item>

          <Form.Item
            label="Search by Order Code"
            name="OrderCode"
          >
            <Input placeholder="Search by Order Code" />
          </Form.Item>

          <Form.Item
            label="All COA Status"
            name="COAStatus"
          >
            <Select
              placeholder="All Status"
              defaultValue="AllStatus"
              options={[
                {
                  value: 'AllStatus',
                  label: 'All Status',
                },
                {
                  value: 'Process',
                  label: 'Process',
                },
                {
                  value: 'Published',
                  label: 'Published',
                },
                {
                  value: 'Cancel',
                  label: 'Cancel',
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Publish Date"
            name="PublishDate"
            className="w-full"
          >
            <RangePicker className="w-full" />
          </Form.Item>

        </div>

        <div className="flex justify-end gap-2">
          <Button type="primary" htmlType="submit">Submit</Button>
          <Button type="primary" htmlType="submit" danger onClick={onResetFilter}>Cancel</Button>
        </div>
      </Form>

      {userData && (userData.Role === "Admin" || userData.Role === "SuperAdmin") && (
        <div className="flex justify-end my-4">
          <Link to={'form'}>
            <Button type="primary" icon={<PlusOutlined />}>Add New</Button>
          </Link>
        </div>
      )}

      <TableCOA isFilteredData={filteredData} isLoading={isLoading} />

    </div>
  )
}

export default SubCOA;
