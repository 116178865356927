import { RollbackOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UploadFile from '../../Order/Form/UploadFile'
import { postCOA, UploadOrderDocs } from '../../../../API/PostData'
import { AuthData } from '../../../../Components/Global'
import InputModal from '../../../../Components/InputModal'
import { getCOAView, getCustomerDetail, getSamples, getSampleView } from '../../../../API/GetData'
import HeaderTitle from '../../../../Components/HeaderTitle'

const FormCOA = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [files, setFiles] = useState(null);
  const userData = AuthData();
  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [dataCOA, setDataCOA] = useState([]);
  const [COACode, setCOACode] = useState("");

  const [dataCustomer, setDataCustomer] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState("");
  const [openCustomer, setOpenCustomer] = useState(null);

  const [dataSample, setDataSample] = useState([]);
  const [selectSample, setSelectSample] = useState("");
  const [openSample, setOpenSample] = useState(null);
  const SampleName = selectSample ? selectSample.SampleName : '';
  const SampleCode = selectSample ? selectSample.SampleCode : '';
  const OrderCode = selectSample ? selectSample.OrderCode : '';

  useEffect(() => {
    const fetchCOA = async () => {
      try {
        const res = await getCOAView();
        setDataCOA(res);
        if (res.length > 0) {
          const filteredData = res.filter(item => item.COACode.startsWith("COA"));
          if (filteredData.length > 0) {
            const lastCode = filteredData[filteredData.length - 1].COACode;
            const nextNumber = parseInt(lastCode.substr(3)) + 1;
            const nextCode = `COA${nextNumber.toString().padStart(3, "0")}`
            setCOACode(nextCode);
          } else {
            setCOACode("COA001");
          }
        } else {
          setCOACode("COA001");
        }
      } catch (error) {
        console.log();
      }
    }
    fetchCOA();
  }, []);


  useEffect(() => {
    if (form && COACode) {
      form.setFieldsValue({ COACode: COACode })
    }
  }, [COACode, form]);

  console.log(dataCOA);
  console.log(dataSample);




  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        setIsLoading(true);
        const res = await getCustomerDetail();
        setDataCustomer(res);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
    if (openCustomer) {
      fetchCustomer();
      setOpenCustomer(false);
      setSelectSample("");
    }
  }, [openCustomer]);

  useEffect(() => {
    const fetchSample = async () => {
      try {
        setIsLoading(true);
        // const res = await getSamples(selectCustomer.AccountID);
        const res = await getSampleView();
        const filterRes = res.filter((item) =>
          item.AccountID === selectCustomer.AccountID &&
          item.Status === null
        )
        setDataSample(filterRes);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
    if (selectCustomer && openSample) {
      fetchSample();
      setOpenSample(false);
    }
  }, [openSample, selectCustomer]);

  useEffect(() => {
    if (form && selectCustomer) {
      form.setFieldsValue({
        SampleName: SampleName,
        CustomerName: selectCustomer.CustomerName,
      })
    }
  }, [SampleName, form, selectCustomer]);



  const handleSubmit = async (values) => {
    try {

      if (!files) {
        message.warning("Upload Document PDF Files!");
        return
      }

      setLoading(true);
      const payload = {
        ...values,
        AccountID: selectCustomer.AccountID,
        OrderCode: OrderCode,
        SampleCode: SampleCode,
        ApprovedBy: userData.Username,
        Status: "Published"
      };
      // if (files) {
      //   payload.Status = "Published";
      // } else {
      //   payload.Status = "Pending";
      // }


      const res = await postCOA(payload);

      if (res.status === 201) {
        if (files) {
          const [firstKey, firstValue] = files.entries().next().value;

          const filePayload = new FormData();

          filePayload.append('AccountID', selectCustomer.AccountID);
          filePayload.append('OrderCode', values.COACode);

          filePayload.append('DocCode', values.COACode);
          filePayload.append('DocType', "COA");

          filePayload.append(firstKey, firstValue);

          const fileResponse = await UploadOrderDocs(filePayload);
          console.log('File upload response:', fileResponse);
        }
      }
      message.success(res.data.message);
      navigate('/dashboard/menuorder/coa');
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
    }
    setLoading(false);
  };

  const handleReset = async () => {
    form.resetFields();
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <HeaderTitle title="COA" subtitle="Form a COA" />
        <Button icon={<RollbackOutlined />} onClick={() => navigate(-1)}>Back</Button>
      </div>

      <div className="bg-white p-4 rounded-md mt-4">
        <Form
          name="formOrder"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
          form={form}
        >

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">

            <Form.Item
              label="COA Code"
              name="COACode"
              rules={[
                {
                  required: true,
                  message: 'Please input your COA Code!',
                },
              ]}
            >
              <Input placeholder="COA Code" />
            </Form.Item>

            <InputModal
              title="CUSTOMER"
              label="Customer"
              name="CustomerName"
              dataSource={dataCustomer}
              loading={isLoading}
              columns={columnsCustomer}
              onData={(values) => setSelectCustomer(values)}
              onOpenModal={(values) => setOpenCustomer(values)}
            />

            <InputModal
              title="SAMPLE"
              label="Sample"
              name="SampleName"
              dataSource={dataSample}
              loading={isLoading}
              columns={columnsSample}
              onData={(values) => setSelectSample(values)}
              onOpenModal={(values) => setOpenSample(values)}
            />

            <Form.Item
              label="Publish Date"
              name="PublishDate"
              rules={[
                {
                  required: true,
                  message: 'Please input your PublishDate!',
                },
              ]}
            >
              <DatePicker className="w-full" />
            </Form.Item>

            <Form.Item
              label="Files"
              name="Files"
              className="md:col-span-2"
            >
              <UploadFile onFiles={(values) => setFiles(values)} />
            </Form.Item>

          </div>

          <div className="flex justify-end gap-2">
            <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
            <Button htmlType="button" onClick={handleReset}>Reset</Button>
          </div>

        </Form>
      </div>
    </>
  )
}

export default FormCOA;


const columnsSample = [
  {
    title: 'Sample Code',
    dataIndex: 'SampleCode',
    key: 'SampleCode',
    fixed: "left",
    // render: (text) => <Link to={`/dashboard/menuorder/sample/${text}`} className="text-indigo-600 hover:text-indigo-400 font-medium">{text}</Link>,
  },
  {
    title: 'Sample Name',
    dataIndex: 'SampleName',
    key: 'SampleName',
  },
  {
    title: 'Order Code',
    dataIndex: 'OrderCode',
    key: 'OrderCode',
  },
  {
    title: 'Customer Name',
    dataIndex: 'CustomerName',
    key: 'CustomerName',
  },
  {
    title: 'Received Date',
    dataIndex: 'ReceivedDate',
    key: 'ReceivedDate',
  },
  {
    title: 'COA Due Date',
    dataIndex: 'COADueDate',
    key: 'COADueDate',
  },
]

const columnsCustomer = [
  {
    title: "AccountID",
    dataIndex: "AccountID",
    key: "AccountID",
  },
  {
    title: "CustomerId",
    dataIndex: "CustomerId",
    key: "CustomerId",
  },
  {
    title: "CustomerName",
    dataIndex: "CustomerName",
    key: "CustomerName",
  },
  {
    title: "ContactPerson",
    dataIndex: "ContactPerson",
    key: "ContactPerson",
  },
  {
    title: "Address",
    dataIndex: "Address",
    key: "Address",
  },
  {
    title: "EmailContact",
    dataIndex: "EmailContact",
    key: "EmailContact",
  },
  {
    title: "Phone",
    dataIndex: "Phone",
    key: "Phone",
  },
  {
    title: "MobilePhone",
    dataIndex: "MobilePhone",
    key: "MobilePhone",
  },
  {
    title: "Fax",
    dataIndex: "Fax",
    key: "Fax",
  },
];