import React from 'react';
import { FloatButton } from 'antd';
import { CommentOutlined, CustomerServiceOutlined } from '@ant-design/icons';
const FloatButtonCS = () => {

    return (
        <>
            <FloatButton.Group
                trigger="click"
                type="primary"
                style={{
                    insetInlineEnd: 24,
                }}
                icon={<CommentOutlined />}
                tooltip={<p>Customer Service</p>}
            >
                <FloatButton tooltip={<p>Message</p>} />
                <FloatButton icon={<CustomerServiceOutlined />} tooltip={<p>Help</p>} />
            </FloatButton.Group>
        </>
    )
}
export default FloatButtonCS;