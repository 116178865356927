import {
  CalendarOutlined,
  ExclamationCircleOutlined,
  ExperimentOutlined,
  FieldTimeOutlined,
  HistoryOutlined,
  ReconciliationOutlined,
  SnippetsOutlined
} from '@ant-design/icons'
import React from 'react'

const DashboardCustomer = () => {

  const dataView = [
    {
      title: "Order No",
      value: "OD.24010001",
      icon: <SnippetsOutlined className="text-[52px]" />
    },
    {
      title: "Sample",
      value: "SL.24010001",
      icon: <ExperimentOutlined className="text-[52px]" />
    },
    {
      title: "WorkSheet",
      value: "WS.24010001",
      icon: <ReconciliationOutlined className="text-[52px]" />
    },
    {
      title: "Status",
      value: "Process",
      icon: <HistoryOutlined className="text-[52px]" />
    },
  ];

  const dataNotif = [
    {
      title: "Sample Recount",
      text: "SL.24010001",
      date: "01/01/2024"
    },
    {
      title: "Invoice",
      text: "INV.24010001",
      date: "01/02/2024"
    }
  ]

  return (
    <>
      <h1 className="text-2xl font-medium mb-2">Dashboard</h1>
      <div className="flex flex-col gap-4">
        {/* <div className="flex-1"> */}
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">

          {dataView.map((item, index) => (
            <div key={index} className="bg-white flex items-center px-4 py-6 rounded-md">
              {item.icon}
              <div className="flex flex-col ml-6 gap-1">
                <h2 className="text-xl font-bold">{item.title}</h2>
                <p className="text-[22px] font-bold text-[#3F94FC]">{item.value}</p>
              </div>
            </div>
          ))}

        </div>
        {/* </div> */}
        <div>
          <h1 className="text-2xl">NOTIFICATIONS</h1>
          <div className="flex flex-col mt-2 gap-2">

            {dataNotif.map((item, index) => (
              <div key={index} className="bg-white p-4 rounded-md flex justify-between">
                <div className="flex gap-4">
                  <ExclamationCircleOutlined className="text-2xl text-gray-400" />
                  <div className="flex flex-col">
                    <p className="text-xl">{item.title}</p>
                    <p className="text-lg font-medium">{item.text}</p>
                  </div>
                </div>
                <div className="flex items-center gap-2 text-xl font-medium">
                  <CalendarOutlined />
                  <p>{item.date}</p>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardCustomer