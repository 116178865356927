import { Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { getOrderDocs } from '../../../../API/GetData';
import { Link, useParams } from 'react-router-dom';

const OrderTab = ({ onDataSource }) => {

  const [form] = Form.useForm();

  const [SupDoc, setSupDoc] = useState([]);
  const [AddDoc, setAddDoc] = useState([]);
  const params = useParams();
  const { accountid, code } = params;

  const OrderCode = onDataSource ? onDataSource.OrderCode : code

  useEffect(() => {
    const fetchDocs = async () => {
      try {
        const res = await getOrderDocs(accountid, OrderCode);
        const resSup = res.filter((item) => item.DocType === 'Support');
        const resAdd = res.filter((item) => item.DocType === 'Additional');
        setSupDoc(resSup);
        setAddDoc(resAdd);
      } catch (error) {
        console.log(error);
      }
    }
    fetchDocs();
  }, [OrderCode, accountid]);


  useEffect(() => {
    if (form && onDataSource) {
      const discountValue = onDataSource.Total * (onDataSource.Discount / 100);
      const vatValue = (onDataSource.Total - discountValue) * (onDataSource.VAT / 100);
      const grandTotal = onDataSource.Total - discountValue + vatValue;
      const payload = {
        ...onDataSource,
        GrandTotal: grandTotal
      }
      form.setFieldsValue(payload);
    }
  }, [form, onDataSource]);


  const FormItemStyle = {
    marginBottom: "0px"
  }



  return (
    <div>

      <Form
        name="basic"
        layout="vertical"
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-0">

          <Form.Item
            label="Order Code"
            name="OrderCode"
            style={FormItemStyle}
          >
            <Input placeholder="Order Code" readOnly />
          </Form.Item>

          <Form.Item
            label="Order Quotation"
            name="OrderQuotation"

            style={FormItemStyle}
          >
            <Input placeholder="Order Quotation" readOnly />
          </Form.Item>

          <Form.Item
            label="Order Date"
            name="OrderDate"

            style={FormItemStyle}
          >
            <Input placeholder="Order Date" readOnly />
          </Form.Item>

          <Form.Item
            label="Received Date"
            name="ReceivedDate"

            style={FormItemStyle}
          >
            <Input placeholder="Received Date" readOnly />
          </Form.Item>

          <Form.Item
            label="Submitted by"
            name="SubmittedBy"

            style={FormItemStyle}
          >
            <Input placeholder="Submitted by" readOnly />
          </Form.Item>

          <Form.Item
            label="Total(IDR)"
            name="Total"

            style={FormItemStyle}
          >
            <Input placeholder="Total(IDR)" readOnly />
          </Form.Item>

          <Form.Item
            label="Discount(%)"
            name="Discount"

            style={FormItemStyle}
          >
            <Input placeholder="Discount(%)" readOnly />
          </Form.Item>

          <Form.Item
            label="VAT(%)"
            name="VAT"

            style={FormItemStyle}
          >
            <Input placeholder="VAT(%)" readOnly />
          </Form.Item>

          <Form.Item
            label="Grand Total(IDR)"
            name="GrandTotal"

            style={FormItemStyle}
          >
            <Input placeholder="GrandTotal(IDR)" readOnly />
          </Form.Item>

        </div>

        <div className="flex flex-col gap-4 my-4">

          <div className="">
            <p>Support Document</p>

            {SupDoc.length > 0 ?
              SupDoc.map((item, index) => (
                <div key={index}>
                  <Link to={`${process.env.REACT_APP_BASEURL_DOC}/${item.PathName}`} target="_blank" className="ml-4">● {item.FileName}</Link>
                </div>
              ))
              :
              "●"
            }
          </div>

          <div className="">
            <p>Additional Document</p>
            {AddDoc.length > 0 ?
              AddDoc.map((item, index) => (
                <div key={index}>
                  <Link to={`${process.env.REACT_APP_BASEURL_DOC}/${item.PathName}`} target="_blank" className="ml-4">● {item.FileName}</Link>
                </div>
              ))
              :
              "●"
            }
          </div>

          <Form.Item
            label="Remarks"
            name="Remarks"

            style={FormItemStyle}
          >
            <Input.TextArea placeholder="Remarks" readOnly rows={4} />
          </Form.Item>
        </div>

      </Form>
    </div>
  )
}

export default OrderTab