import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import React from 'react'

const { RangePicker } = DatePicker;

const rangePresets = [
    {
        label: 'Last 7 Days',
        value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
        label: 'Last 14 Days',
        value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
        label: 'Last 30 Days',
        value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
        label: 'Last 90 Days',
        value: [dayjs().add(-90, 'd'), dayjs()],
    },
];

const FilterDate = ({ onDate1, onDate2 }) => {
    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            // console.log('From: ', dates[0], ', to: ', dates[1]);
            // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            onDate1(dateStrings[0])
            onDate2(dateStrings[1])
        } else {
            console.log('Clear');
            onDate1("")
            onDate2("")
        }
    };
    return (
        <div className="">
            <div className="flex flex-col justify-end items-end mb-4">
                {/* <p>Filter Date</p> */}
                <RangePicker presets={rangePresets} onChange={onRangeChange} />
            </div>
        </div >
    )
}

export default FilterDate