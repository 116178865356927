import axios from "axios"

const baseURL = process.env.REACT_APP_BASEURL;

export const DelAccountAdmin = async (AccountID, CustomerId, UserCode) => {
    const response = await axios.delete(`${baseURL}/dashboard/delete/account_admin?AccountID=${AccountID}&CustomerId=${CustomerId}&UserCode=${UserCode}`);
    return response;
}

export const DelNews = async (NewsCode) => {
    const response = await axios.delete(`${baseURL}/dashboard/delete/news?NewsCode=${NewsCode}`)
    return response
}

export const DelCustomerAccount = async (AccountID, CustomerId) => {
    const response = await axios.delete(`${baseURL}/dashboard/delete/customer_account?AccountID=${AccountID}&CustomerId=${CustomerId}`);
    return response;
}
