import React, { useEffect, useState } from 'react';
import { getNews } from '../../../API/GetData';
import { Link } from 'react-router-dom';
import { Carousel, Spin } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const NextArrow = ({ onClick }) => (
    <div
        className="absolute top-1/2 right-4 transform -translate-y-1/2 z-10 bg-black bg-opacity-45 text-white p-2 rounded-md cursor-pointer hover:bg-gray-700"
        onClick={onClick}
    >
        <RightOutlined />
    </div>
);

const PrevArrow = ({ onClick }) => (
    <div
        className="absolute top-1/2 left-4 transform -translate-y-1/2 z-10 bg-black bg-opacity-45 text-white p-2 rounded-md cursor-pointer hover:bg-gray-700"
        onClick={onClick}
    >
        <LeftOutlined />
    </div>
);

const CarouselDashboard = () => {
    const [dataBanner, setDataBanner] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await getNews();
                setDataBanner(res);
            } catch (error) {
                console.error('Error fetching news:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-96">
                <Spin size="large" />
            </div>
        );
    }

    if (dataBanner.length === 0) {
        // return <div className="text-center h-96 flex items-center justify-center">No banner data available.</div>;
        return ""
    }

    return (
        <div className="relative mb-4">
            <Carousel
                arrows
                autoplay
                autoplaySpeed={3000}
                nextArrow={<NextArrow />}
                prevArrow={<PrevArrow />}
            >
                {dataBanner.map((item) => (
                    <Link key={item.NewsCode} to={`/dashboard/detailbanner/${item.NewsCode}`}>
                        <img
                            src={`${process.env.REACT_APP_BASEURL_DOC}/${item.ImageNews}`}
                            alt={item.TitleNews}
                            className="w-full h-96 rounded-lg object-cover"
                        />
                    </Link>
                ))}
            </Carousel>
        </div>
    );
};

export default CarouselDashboard;