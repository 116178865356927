import { EyeInvisibleOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { getAccountAdmin } from '../../API/GetData';
import HeaderTitle from '../../Components/HeaderTitle';
import { Link } from 'react-router-dom';
import SearchInput from '../../Components/SearchInput';
import EditAccountAdmin from './edit';
import DeleteAccountAdmin from './delete';

const AccountAdmin = () => {
  const [showPassword, setShowPassword] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchText, setSearchText] = useState('');

  const toggleShowPassword = (key) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const fetchData = async () => {
    try {
      const res = await getAccountAdmin();
      setData(res);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    setLoading(false);
  }, []);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Username",
      dataIndex: "Username",
      key: "Username",
    },
    {
      title: "Password",
      dataIndex: "Password",
      key: "Password",
      render: (text, row) => (
        <div className="flex items-center">
          <p>{showPassword[row.key] ? text : "**********"}</p>
          <Button
            type="link"
            onClick={() => toggleShowPassword(row.key)}
          >
            {showPassword[row.key] ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </Button>
        </div>
      ),
    },
    {
      title: "Action",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space>
          <EditAccountAdmin onData={record} onEdit={fetchData} />
          <DeleteAccountAdmin onData={record} onDelete={fetchData} />
        </Space>
      ),
    },
  ];

  return (

    <>
      <div className="flex items-center justify-between">
        <HeaderTitle title="ADMIN ACCOUNT" subtitle="All data a Admin Account" />
        <Link to={'form'}>
          <Button type="primary" icon={<PlusOutlined />}>Add New</Button>
        </Link>
      </div>

      <div className="bg-white p-4 rounded-md mt-4">
        <div className="flex justify-end mb-2">
          <SearchInput value={searchText} onChange={handleSearch} />
        </div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={filteredData}
          scroll={
            columns.length > 8 && { x: 2000 }
          }
        />

      </div>
    </>
  );
}

export default AccountAdmin