import { CheckCircleOutlined, CloseCircleOutlined, FileSyncOutlined, HistoryOutlined, SnippetsOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const TableInvoice = ({ isFilteredData, isLoading }) => {

    const columns = [
        {
            title: 'Invoice No',
            dataIndex: 'InvoiceNo',
            key: 'InvoiceNo',
            fixed: "left",
            render: (text, record) =>
                <>
                    <Link
                        to={`/invoice/print/${record.AccountID}/${record.InvoiceNo}`}
                        target="_blank"
                        className="text-indigo-600 hover:text-indigo-400 font-medium"
                    >
                        <Button
                            icon={<SnippetsOutlined />}
                            type="text"
                        />
                    </Link>
                    <Link to={`${record.AccountID}/${text}`} className="text-indigo-600 hover:text-indigo-400 font-medium">{text}</Link>
                </>
        },
        {
            title: 'Invoice Date',
            dataIndex: 'InvoiceDate',
            key: 'InvoiceDate',
        },
        {
            title: 'Order Code',
            dataIndex: 'OrderCode',
            key: 'OrderCode',
            render: (text, record) => <Link to={`/dashboard/menuorder/order/${record.AccountID}/${text}`} className="text-indigo-600 hover:text-indigo-400 font-medium">{text}</Link>,
        },
        {
            title: 'Tax Faktur No',
            dataIndex: 'TaxFakturNo',
            key: 'TaxFakturNo',
            // render: (text, record) =>
            //     <Link
            //         to={`/faktur/${record.AccountID}/${record.InvoiceNo}`}
            //         target="_blank"
            //         className="text-indigo-600 hover:text-indigo-400 font-medium"
            //     >
            //         {text}
            //     </Link>
            // record.PaymentStatus === "PAID" ?
            //     <Link
            //         to={`/faktur/${record.AccountID}/${record.InvoiceNo}`}
            //         target="_blank"
            //         className="text-indigo-600 hover:text-indigo-400 font-medium"
            //     >
            //         {text}
            //     </Link>
            //     : <p>{text}</p>
        },
        {
            title: 'Customer Name',
            dataIndex: 'CustomerName',
            key: 'CustomerName',
        },
        {
            title: 'Submitted By',
            dataIndex: 'SubmittedBy',
            key: 'SubmittedBy',
        },
        {
            title: 'Invoice Sent Date',
            dataIndex: 'InvoiceSentDate',
            key: 'InvoiceSentDate',
        },
        {
            title: 'Payment Status',
            dataIndex: 'PaymentStatus',
            key: 'PaymentStatus',
            render: (value) => {
                let bgColor;
                let Icon;

                switch (value) {
                    case "UNPAID":
                        bgColor = "bg-gray-400";
                        Icon = <SnippetsOutlined />
                        break;
                    case "PENDING REVIEW":
                        bgColor = "bg-blue-500";
                        Icon = <HistoryOutlined />
                        break;
                    case "REVISION NEEDED":
                        bgColor = "bg-yellow-400";
                        Icon = <FileSyncOutlined />
                        break;
                    case "PAID":
                        bgColor = "bg-green-500";
                        Icon = <CheckCircleOutlined />
                        break;
                    case "REJECT":
                        bgColor = "bg-red-500";
                        Icon = <CloseCircleOutlined />
                        break;
                    default:
                        bgColor = "bg-gray-300";
                        Icon = <CloseCircleOutlined />
                }

                return (
                    <p className={`${bgColor} text-white text-center p-1 rounded-xl`}>
                        {value} {Icon}
                    </p>
                );
            }
        }

    ]

    return (
        <>
            <Table
                columns={columns}
                dataSource={isFilteredData}
                loading={isLoading}
                scroll={{
                    x: 1600,
                }}
            />
        </>
    )
}

export default TableInvoice