import { EditFilled, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, message, Modal, Tooltip, Upload } from "antd";
import React, { useState, useEffect } from "react";
import HeaderTitle from "../../Components/HeaderTitle";
import { UpdateNews } from "../../API/UpdateData";
import TextArea from "antd/es/input/TextArea";

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const EditNews = ({ onData, onEdit }) => {

    const UrlImage = `${process.env.REACT_APP_BASEURL_DOC}/${onData.ImageNews}`

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    const [imageUrl, setImageUrl] = useState(UrlImage);
    const [imageData, setImageData] = useState(null);

    const handleChange = (info) => {
        const { file } = info;
        if (file && file.originFileObj) {
            const allowedExtensions = ['png', 'jpg', 'jpeg'];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (allowedExtensions.includes(fileExtension)) {
                getBase64(file.originFileObj, (base64) => {
                    setImageUrl(base64);
                });
                setImageData(file.originFileObj)
            } else {
                message.error('The uploaded file must be an image with the extension .png, .jpg, or .jpeg');
            }
        }
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    useEffect(() => {
        if (isModalOpen && onData) {
            form.setFieldsValue(onData);
        }
    }, [form, isModalOpen, onData]);


    const showModal = () => {
        setIsModalOpen(true);
    };

    const onFinish = async (values) => {
        try {
            const payload = {
                ...values,
                ImageNews: imageData
            }
            console.log("on Submit", payload);

            const response = await UpdateNews(onData.NewsCode, payload);
            message.success(response.data.message);
            onEdit(true);
            setIsModalOpen(false);
        } catch (error) {
            console.log(error);
            message.error(error.response.data.message);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleReset = () => {
        form.resetFields();
        setIsModalOpen(false);
    };

    return (
        <>
            <Tooltip title="Edit">
                <Button icon={<EditFilled />} type="text" onClick={showModal} />
            </Tooltip>

            <Modal
                title={<HeaderTitle title="NEWS" subtitle="Edit data a News" />}
                centered
                open={isModalOpen}
                closable={false}
                width={1000}
                styles={{
                    body: {
                        maxHeight: "70vh",
                        overflow: "auto",
                    },
                }}
                footer={false}
            >
                <Form
                    name="basic"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                >

                    <div className="flex items-center justify-center pt-8">
                        <Form.Item>
                            <p className="text-center text-lg font-medium mb-2">Upload Image</p>
                            {imageUrl ? (
                                <div className="shadow-md">
                                    <Image
                                        src={imageUrl}
                                        alt="avatar"
                                        style={{ width: "140px", height: "auto", margin: "0", padding: "0" }}
                                    />
                                    <div className="flex w-full">
                                        <Upload
                                            showUploadList={false}
                                            onChange={handleChange}
                                            className="w-full bg-white hover:bg-gray-200 py-1 text-center"
                                        >
                                            <button type="button" className="w-32"><UploadOutlined /></button>
                                        </Upload>
                                    </div>
                                </div>
                            ) : (
                                <Upload
                                    name="file"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    onChange={handleChange}
                                >
                                    {uploadButton}
                                </Upload>
                            )}
                        </Form.Item>
                    </div>

                    <Form.Item
                        label="News Code"
                        name="NewsCode"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your News Code!',
                            },
                        ]}
                    >
                        <Input placeholder="News Code" readOnly />
                    </Form.Item>

                    <Form.Item
                        label="Title News"
                        name="TitleNews"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Title News!',
                            },
                        ]}
                    >
                        <Input placeholder="Title News" />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="Description"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Description!',
                            },
                        ]}
                    >
                        <TextArea rows={4} placeholder="Description" />
                    </Form.Item>

                    <div className="flex justify-end gap-2">
                        <Button type="primary" htmlType="submit">Submit</Button>
                        <Button htmlType="button" onClick={handleReset}>Cancel</Button>
                    </div>

                </Form>
            </Modal>
        </>
    );
};

export default EditNews;
