import React from 'react';
import { Table } from 'antd';
import { formatRupiah } from '../../../Components/Global';

const columns = [
    {
        title: 'Order Code',
        dataIndex: 'OrderCode',
        key: 'OrderCode'
    },
    {
        title: 'Customer',
        dataIndex: 'CustomerName',
        key: 'Customer'
    },
    {
        title: 'Create Date',
        dataIndex: 'ReceivedDate',
        key: 'Create Date'
    },
    {
        title: 'Value Order',
        dataIndex: 'Total',
        key: 'ValueOrder',
        render(text) {
            return (
                <div className="flex justify-between">
                    <p>Rp</p> <p>{formatRupiah(text ? text : 0)}</p>
                </div>
            )
        }
    },
];

// const data = [
//     {
//         OrderCode: "OD.23110928",
//         Customer: "PT. Intertek Utama Services",
//         CreateDate: "2023-11-16 08:19:23",
//         ValueOrder: "910000",
//     },
// ];

// Subtotal
// Diskon
// total = Subtotal - Diskon
// VAT = 11% (Dari total)
// GrandTotal = total + VAT

const InvoiceOrder = ({ dataSource }) => {
    const Total = dataSource ? dataSource.Total : 0;
    const Discount = dataSource ? dataSource.Discount : 0;
    const VAT = dataSource ? dataSource.VAT : 0;

    const TotalDiscount = Total * (Discount / 100);
    const DPP = Total - TotalDiscount;
    const TotalVAT = DPP * (VAT / 100);
    const NET = DPP + TotalVAT;

    return (
        <Table
            columns={columns}
            dataSource={[dataSource]}
            pagination={false}
            summary={() => {
                return (
                    <>
                        {/* <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3}>
                                <p className="font-bold">Gross</p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                                <div className="flex justify-between">
                                    <p>Rp</p> <p>{formatRupiah(Total)}</p>
                                </div>
                            </Table.Summary.Cell>
                        </Table.Summary.Row> */}
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3}>
                                <p className="font-bold">Diskon ({dataSource.Discount}%)</p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                                <div className="flex justify-between">
                                    <p>Rp</p> <p>{formatRupiah(TotalDiscount)}</p>
                                </div>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3}>
                                <p className="font-bold">DPP</p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                                <div className="flex justify-between">
                                    <p>Rp</p> <p>{formatRupiah(DPP)}</p>
                                </div>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3}>
                                <p className="font-bold">VAT ({VAT}%)</p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                                <div className="flex justify-between">
                                    <p>Rp</p> <p>{formatRupiah(TotalVAT)}</p>
                                </div>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3}>
                                <p className="font-bold">NET</p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                                <div className="flex justify-between">
                                    <p>Rp</p> <p>{formatRupiah(NET)}</p>
                                </div>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </>
                )
            }}

        />
    )
};
export default InvoiceOrder;