import { EditFilled } from "@ant-design/icons";
import { Button, Form, Input, message, Modal, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import HeaderTitle from "../../Components/HeaderTitle";
import FormUsers from "./Users/form";
import FormCustomerCC from "./CustomerCC/form";
import { UpdateCustomerAccount } from "../../API/UpdateData";

const EditAccount = ({ onData, onEdit }) => {

    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [dataCustomerCC, setDataCustomerCC] = useState([]);
    const [dataUsers, setDataUsers] = useState([]);

    useEffect(() => {
        if (isModalOpen && onData) {
            form.setFieldsValue(onData);
        }
    }, [form, isModalOpen, onData]);


    const showModal = () => {
        setIsModalOpen(true);
    };

    const hanldeSubmit = async (values) => {
        try {
            const payload = {
                ...values,
                customercc: dataCustomerCC,
                users: dataUsers
            };
            // console.log(payload);

            const response = await UpdateCustomerAccount(payload);
            message.success(response.data.message);
            onEdit(true);
            setIsModalOpen(false);
        } catch (error) {
            console.log(error);
            message.error(error.response.data.message);
        }
    };

    const handleReset = () => {
        form.resetFields();
        setIsModalOpen(false);
    };

    const onSubmit = () => {
        form.submit();
    }

    return (
        <>
            <Tooltip title="Edit">
                <Button icon={<EditFilled />} type="text" onClick={showModal} />
            </Tooltip>

            <Modal
                title={<HeaderTitle title="ADMIN ACCOUNT" subtitle="Edit data a Admin Account" />}
                centered
                open={isModalOpen}
                closable={false}
                width={1000}
                styles={{
                    body: {
                        maxHeight: "70vh",
                        overflow: "auto",
                    },
                }}
                footer={
                    <div className="flex justify-end gap-2">
                        <Button type="primary" htmlType="submit" onClick={onSubmit}>Submit</Button>
                        <Button htmlType="button" onClick={handleReset}>Cancel</Button>
                    </div>
                }
            >
                <Form
                    name="basic"
                    layout="vertical"
                    onFinish={hanldeSubmit}
                    autoComplete="off"
                    form={form}
                    className="px-2"
                >

                    <Form.Item
                        label="Account ID"
                        name="AccountID"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Account ID!',
                            },
                        ]}
                    >
                        <Input placeholder="Account ID" readOnly />
                    </Form.Item>

                    <Form.Item
                        label="Customer Id"
                        name="CustomerId"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Customer Id!',
                            },
                        ]}
                    >
                        <Input placeholder="Customer Id" readOnly />
                    </Form.Item>

                    <Form.Item
                        label="Customer Name"
                        name="CustomerName"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your CustomerName!',
                            },
                        ]}
                    >
                        <Input placeholder="Customer Name" />
                    </Form.Item>

                    <Form.Item
                        label="Email Contact"
                        name="EmailContact"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Email Contact!',
                            },
                        ]}
                    >
                        <Input placeholder="Email Contact" />
                    </Form.Item>

                    <Form.Item
                        label="Contact Person"
                        name="ContactPerson"
                    >
                        <Input placeholder="Contact Person" />
                    </Form.Item>

                    <Form.Item
                        label="Address"
                        name="Address"
                    >
                        <Input placeholder="Address" />
                    </Form.Item>

                    <Form.Item
                        label="Phone"
                        name="Phone"
                    >
                        <Input placeholder="Phone" />
                    </Form.Item>

                    <Form.Item
                        label="Mobile Phone"
                        name="MobilePhone"
                    >
                        <Input placeholder="Mobile Phone" />
                    </Form.Item>

                    <Form.Item
                        label="Fax"
                        name="Fax"
                    >
                        <Input placeholder="Fax" />
                    </Form.Item>

                    {/* DETAIL CC */}
                    <div className="border rounded-md mb-4 p-4">
                        <FormCustomerCC
                            onSaveData={(values) => setDataCustomerCC(values)}
                            onEdit={onData.cc}
                        />
                    </div>

                    {/* DETAIL USERS */}
                    <div className="border rounded-md mb-4 p-4">
                        <FormUsers
                            onSaveData={(values) => setDataUsers(values)}
                            onEdit={onData.users}
                        />
                    </div>

                </Form>
            </Modal>
        </>
    );
};

export default EditAccount;
