import React, { useEffect, useState } from 'react'
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import SearchInput from '../../../Components/SearchInput';

const DataUnderProcess = ({ onData, onTotalData }) => {

    const [searchText, setSearchText] = useState('');

    const data = onData.filter((item) => item.PaymentStatus !== null && item.PaymentStatus !== "PAID");

    const columns = [
        {
            title: 'Order Code',
            dataIndex: 'OrderCode',
            key: 'OrderCode',
            sorter: (a, b) => a.OrderCode.localeCompare(b.OrderCode),
            render: (text) => <Link to="" className="text-indigo-600 hover:text-indigo-400 font-medium">{text}</Link>,
        },
        {
            title: 'Customer Name',
            dataIndex: 'CustomerName',
            key: 'CustomerName',
            sorter: (a, b) => a.CustomerName.localeCompare(b.CustomerName),
        },
        {
            title: 'Received Date',
            dataIndex: 'ReceivedDate',
            key: 'ReceivedDate',
            sorter: (a, b) => new Date(a.ReceivedDate) - new Date(b.ReceivedDate),
        },
    ];


    useEffect(() => {
        const totalData = data ? data.length : 0;
        onTotalData(totalData);
    }, [data, onTotalData]);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some((val) =>
            val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    return (
        <div>
            <div className="flex justify-end mb-2">
                <SearchInput value={searchText} onChange={handleSearch} />
            </div>
            <Table columns={columns} dataSource={filteredData} />
        </div>
    )
}

export default DataUnderProcess