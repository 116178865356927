import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AuthLogin } from '../../API/PostData';

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Username: '',
    Password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await AuthLogin(formData);
      if (response) {
        const randomString = generateRandomString(50);
        Cookies.set('auth_token', randomString, { expires: 1 });
        localStorage.clear();
        navigate('/dashboard');
        localStorage.setItem("Auth", JSON.stringify(response.data));
        message.success(`Welcome back ${response.data.Username}!`);
      } else {
        message.error("Username and Password dont match!")
      }
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
    }
    setLoading(false);
  };

  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="h-screen max-w-screen mx-auto flex items-center justify-center bg-gray-100">
      <div className="max-h-screen-lg max-w-screen-lg w-full flex drop-shadow-md">

        <div className="w-1/2 bg-cover bg-center rounded-s-xl hidden lg:block" style={{ backgroundImage: "url('/assets/images/img-login.jpeg')" }} />

        <div className="bg-white py-6 lg:py-10 px-8 lg:px-14 w-full lg:w-1/2 lg:rounded-e-xl mx-4 lg:mx-0">
          {/* <img src="/assets/images/logo.png" alt="Logo Icon" className="w-20 md:w-28 h-20 md:h-28" /> */}
          <p className="text-4xl drop-shadow-md font-bold">SA-CRMS</p>
          <div className="py-6">
            <h1 className="text-[42px] md:text-[58px] text-indigo-700 leading-none">Hello, <span className="font-bold">Welcome!</span></h1>
          </div>
          <div className="my-2">
            <form onSubmit={handleSubmit} method="POST">
              <div className="flex flex-col gap-4">
                <div>
                  <label htmlFor="Username" className="block text-sm font-medium leading-6 text-gray-900">
                    Username
                  </label>
                  <div className="mt-2">
                    <input
                      id="Username"
                      name="Username"
                      type="text"
                      required
                      autoComplete="Username"
                      value={formData.Username}
                      onChange={handleChange}
                      placeholder="Username"
                      className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="Password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  <div className="mt-2 relative">
                    <input
                      id="Password"
                      name="Password"
                      type={showPassword ? "text" : "Password"}
                      required
                      autoComplete="current-Password"
                      value={formData.Password}
                      onChange={handleChange}
                      placeholder="Password"
                      className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="text-gray-500 hover:text-gray-700 absolute right-2 top-1.5"
                    >
                      {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    </button>
                  </div>
                </div>
              </div>

              <Link to="">
                <p className="text-right pt-2 text-indigo-700 hover:text-indigo-400">Forget Password?</p>
              </Link>

              <div className="flex gap-4">
                <button type="submit" className={`${loading ? "bg-indigo-500" : "bg-indigo-700"} hover:bg-indigo-500 text-white py-2 max-w-32 w-full rounded-md mt-8`} disabled={loading}>
                  {loading ? "Loading..." : "Log in"}
                </button>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Login;
