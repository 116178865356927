import { Button, DatePicker, Form, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import TableSample from './TableSample';
import { getSampleView } from '../../../API/GetData';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const Sample = () => {
  const [filterForm] = Form.useForm();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (Date1, Date2) => {
    try {
      const response = await getSampleView(Date1, Date2);
      setData(response);
      setFilteredData(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onFilterData = async (values) => {
    const { CodeorName, OrderCode, Status, COADueDate } = values;
    try {
      setIsLoading(true);
      let filtered = data;
      if (COADueDate) {
        const Date1 = dayjs(COADueDate[0]).format('YYYY-MM-DD')
        const Date2 = dayjs(COADueDate[1]).format('YYYY-MM-DD')
        fetchData(Date1, Date2);
      }

      if (CodeorName) {
        filtered = filtered.filter(item =>
          item.SampleCode.toLowerCase().includes(CodeorName.toLowerCase()) ||
          item.SampleName.toLowerCase().includes(CodeorName.toLowerCase())
        );
      }

      if (OrderCode) {
        filtered = filtered.filter(item =>
          item.OrderCode.toLowerCase().includes(OrderCode.toLowerCase())
        );
      }

      if (Status && Status !== 'AllStatus') {
        filtered = filtered.filter(item =>
          item.Status === Status
        );
      }

      if (COADueDate) {
        const [start, end] = COADueDate;
        filtered = filtered.filter(item =>
          new Date(item.COADueDate) >= start.toDate() && new Date(item.COADueDate) <= end.toDate()
        );
      }

      setFilteredData(filtered);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onResetFilter = () => {
    filterForm.resetFields();
    fetchData();
  };

  return (
    <div className="bg-white p-4 rounded-md">
      <h1 className="w-full border-b-2 text-lg pb-2">List of Sample</h1>

      <Form
        name="filterForm"
        layout="vertical"
        onFinish={onFilterData}
        autoComplete="off"
        form={filterForm}
        className="relative border-2 rounded-md p-4 my-4"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">

          <Form.Item
            label="Search Sample Code or Name"
            name="CodeorName"
          >
            <Input placeholder="Search Sample Code or Name" />
          </Form.Item>

          <Form.Item
            label="Search by Order Code"
            name="OrderCode"
          >
            <Input placeholder="Search by Order Code" />
          </Form.Item>

          <Form.Item
            label="All Status"
            name="Status"
          >
            <Select
              placeholder="All Status"
              defaultValue="AllStatus"
              options={[
                {
                  value: 'AllStatus',
                  label: 'All Status',
                },
                {
                  value: 'Process',
                  label: 'Process',
                },
                {
                  value: 'Published',
                  label: 'Published',
                },
                {
                  value: 'Cancel',
                  label: 'Cancel',
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="COA Release Due Date"
            name="COADueDate"
            className="w-full"
          >
            <RangePicker className="w-full" />
          </Form.Item>

        </div>

        <div className="flex justify-end gap-2">
          <Button type="primary" htmlType="submit">Submit</Button>
          <Button type="primary" htmlType="submit" danger onClick={onResetFilter}>Cancel</Button>
        </div>

      </Form >

      <TableSample isFilteredData={filteredData} isLoading={isLoading} />

    </div >
  )
}

export default Sample;
