import { Tabs } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import DataCreated from './DataCreated';
import DataUnderProcess from './DataUnderProcess';
import DataCompleted from './DataCompleted';
import { getOrderView } from '../../../API/GetData';

// const onChange = (key) => {
//     console.log(key);
// };


const OrderStatus = ({ onDate1, onDate2 }) => {
    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState('');

    const fetchData = useCallback(async () => {
        try {
            const res = await getOrderView(onDate1, onDate2);
            setData(res);
        } catch (error) {
            console.log(error);
        }
    }, [onDate1, onDate2]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // console.log([data]);

    const items = [
        {
            key: '1',
            label: 'Created',
            children: <DataCreated onData={data} onTotalData={(value) => setTotalData(value)} />,
        },
        {
            key: '2',
            label: 'Under Process',
            children: <DataUnderProcess onData={data} onTotalData={(value) => setTotalData(value)} />,
        },
        {
            key: '3',
            label: 'Completed',
            children: <DataCompleted onData={data} onTotalData={(value) => setTotalData(value)} />,
        },
    ];

    return (
        <div className="flex flex-col gap-4 bg-white p-4 rounded-md">
            <div className="flex justify-between items-center">
                <p className="text-2xl">Order Status</p>
                <p>Total Item: {totalData}</p>
            </div>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    )
}

export default OrderStatus