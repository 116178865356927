import { Form, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import SampleCOADetail from '../FormDetail/SampleCOADetail';
import { getCustomerDetail } from '../../../../../API/GetData';
import TableWorksheet from '../FormDetail/TableWorksheet';
import { useParams } from 'react-router-dom';

const Customer = ({ onDataSource }) => {
  const params = useParams();
  const { accountid } = params;
  const [form] = Form.useForm();
  const [dataCustomer, setDataCustomer] = useState({}); // Initialize as an empty object

  // Safely access dataCustomer.cc and default to an empty array if it's undefined
  const CustomerCC = dataCustomer.cc ? dataCustomer.cc : [];

  useEffect(() => {
    const fetchCust = async () => {
      try {
        const response = await getCustomerDetail(accountid);
        setDataCustomer(response[0]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCust();
  }, [accountid]);

  useEffect(() => {
    if (form && dataCustomer) {
      form.setFieldsValue(dataCustomer);
    }
  }, [form, dataCustomer]);

  const onsubmit = (values) => {
    console.log("Submit", values);
  };

  return (
    <>
      <Form
        name="filterForm"
        layout="vertical"
        onFinish={onsubmit}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="Customer Name"
          name="CustomerName"
        >
          <Input placeholder="Customer Name" disabled />
        </Form.Item>

        <Form.Item
          label="Mobile Phone"
          name="MobilePhone"
        >
          <Input placeholder="Mobile Phone" disabled />
        </Form.Item>

        <Form.Item
          label="Email Contact"
          name="EmailContact"
        >
          <Input placeholder="Email Contact" disabled />
        </Form.Item>

        <Form.Item
          label="Address"
          name="Address"
        >
          <Input placeholder="Address" disabled />
        </Form.Item>

        <Form.Item
          label="Fax"
          name="Fax"
        >
          <Input placeholder="Fax" disabled />
        </Form.Item>
      </Form>

      <div className="w-full">
        <p className="font-bold border-b-2 pb-1">CC Email</p>
        {CustomerCC.map((item, index) => (
          <div key={index} className="grid grid-cols-2 border-b-2 py-2">
            <p className="font-bold">{item.CCName}</p>
            <p>{item.CCEmail}</p>
          </div>
        ))}
      </div>

      <div className="flex flex-col gap-8 mt-8">
        <TableWorksheet onDataSource={onDataSource.Worksheet} />
        <SampleCOADetail onDataSource={onDataSource.COA} />
      </div>
    </>
  );
};

export default Customer;
