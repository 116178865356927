import { Table } from 'antd'
import React from 'react'

const TableAnalytic = ({ columns, dataSource, isLoading }) => {

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            loading={isLoading}
            // scroll={columns.length > 5 && { x: 1000 }}
            // pagination={dataSource.length < 10 ? false : { pageSize: 5 }}
            pagination={{ pageSize: 5 }}
        />
    )
}

export default TableAnalytic