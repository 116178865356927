import { RollbackOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, Input, message, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCustomerDetail, getInvoices, getOrders, getOrderView } from '../../../API/GetData'
import { postInvoice, SendEmail } from '../../../API/PostData'
import { formatRupiah } from '../../../Components/Global'
import InputModal from '../../../Components/InputModal'
import TableInvoice from '../DetailInvoice/InvoiceOrder'
import dayjs from 'dayjs'
import HeaderTitle from '../../../Components/HeaderTitle'

const emailTemplate = (values, dataUser, dataOrder) => `
    <!DOCTYPE html>
    <html lang="id">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Process Order</title>
       <style>
                body { font-family: Arial, sans-serif; background-color: #f4f4f4; }
                .email-container { max-width: 600px; margin: 0 auto; padding: 20px; background-color: #fff; }
                .header { text-align: center; }
                .content { color: #333; }
                .order-info { font-weight: bold; color: #312E81; }
                .btn-download { background-color: #312E81; color: #fff; padding: 10px 20px; text-decoration: none; border-radius: 5px; cursor: pointer; }
                .footer { text-align: center; color: #777; }
            </style>
    </head>
    <body>
        <div class="email-container">
            <div class="header">
                <h2>Halo, ${dataUser.CustomerName}</h2>
            </div>
            <div class="content">
                <p>Invoice dengan No.${values.InvoiceNo} dari Testing Order dengan Kode : ${dataOrder.OrderCode} telah tebit</p>
                
                <p>Untuk mengetahui lebih detail informasi Invoice, Anda dapat mengujungi
                    <a href="https://sainsanalitikaprima.co.id/" target="_blank">
                        Customer Portal Kami
                    </a>
                .</p>

            </div>
            <div class="footer">
                <p>Salam,<br>Sains Analitika Prima Lab</p>
            </div>
        </div>
    </body>
    </html>
`;

const FormInvoice = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [invoiceNo, setInvoiceNo] = useState("");

    const [dataCustomer, setDataCustomer] = useState([]);
    const [selectCustomer, setSelectCustomer] = useState("");
    const [openCustomer, setOpenCustomer] = useState(null);

    const [dataOrder, setDataOrder] = useState([]);
    const [selectOrder, setSelectOrder] = useState("");
    const [openOrder, setOpenOrder] = useState(null);
    const OrderCode = selectOrder ? selectOrder.OrderCode : '';

    console.log(selectOrder);
    
    

    useEffect(() => {
        const fetchInvoices = async () => {
            try {
                const res = await getInvoices();
                if (res.length > 0) {
                    const filteredData = res.filter(item => item.InvoiceNo.startsWith("INV"));
                    if (filteredData.length > 0) {
                        const lastNo = filteredData[filteredData.length - 1].InvoiceNo
                        const nextNumber = parseInt(lastNo.substr(3)) + 1;
                        const nextNo = `INV${nextNumber.toString().padStart(3, "0")}`
                        setInvoiceNo(nextNo)
                    } else {
                        setInvoiceNo("INV001");
                    }
                } else {
                    setInvoiceNo("INV001");
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchInvoices();
    }, []);

    useEffect(() => {
        if (form && invoiceNo) {
            form.setFieldsValue({ InvoiceNo: invoiceNo })
        }
    }, [form, invoiceNo]);



    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const res = await getCustomerDetail();
                setDataCustomer(res);
            } catch (error) {
                console.log(error);
            }
        }
        if (openCustomer) {
            fetchCustomer();
            setOpenCustomer(false);
            setIsLoading(false);
        }
    }, [openCustomer]);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const res = await getOrderView();
                const filterRes = res.filter((item) =>
                    item.AccountID === selectCustomer.AccountID &&
                    item.PaymentStatus === null
                );
                setDataOrder(filterRes);
            } catch (error) {
                console.log(error);
            }
        }
        if (openOrder) {
            fetchOrder();
            setOpenOrder(false);
            setSelectOrder("");
            setIsLoading(false);
        }
    }, [openOrder, selectCustomer.AccountID]);

    useEffect(() => {
        if (form && selectCustomer) {
            form.setFieldsValue({
                OrderCode: OrderCode,
                CustomerName: selectCustomer.CustomerName,
            })
        }
    }, [OrderCode, form, selectCustomer]);


    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            const payload = {
                ...values,
                AccountID: selectCustomer.AccountID,
                InvoiceSentDate: dayjs(),
                Total: selectOrder.Total,
                Discount: selectOrder.Discount,
                VAT: selectOrder.VAT,
                NET: selectOrder.NET
            };
            const response = await postInvoice(payload);

            if (response) {
                const payloadEmail = {
                    email: selectCustomer ? selectCustomer.EmailContact.toString() : "aditiaramadhan301101@gmail.com",
                    subject: `Invoice dengan No.${values.InvoiceNo}, Sains Analitika Prima Lab`,
                    message: "",
                    html: emailTemplate(values, selectCustomer, selectOrder),
                }
                const resEmail = await SendEmail(payloadEmail);
                // console.log(resEmail);
            }

            message.success(response.data.message);
            navigate('/dashboard/menuinvoice/invoice');
        } catch (error) {
            console.log(error);
            message.error(error.response.data.message);
        }
        setLoading(false);
    };

    const handleReset = async () => {
        form.resetFields();
        setSelectOrder("");
    }

    return (
        <>
            <div className="flex justify-between items-center">
                <HeaderTitle title="INVOICE" subtitle="Form a Invoice" />
                <Button icon={<RollbackOutlined />} onClick={() => navigate(-1)}>Back</Button>
            </div>

            <div className="bg-white p-4 rounded-md mt-4">
                <Form
                    name="formOrder"
                    layout="vertical"
                    onFinish={handleSubmit}
                    autoComplete="off"
                    form={form}
                >
                    <h1 className="text-xl font-semibold border-b-2 pb-1 mb-2">Informasi Invoice</h1>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">

                        <Form.Item
                            label="Invoice No"
                            name="InvoiceNo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Invoice No!',
                                },
                            ]}
                        >
                            <Input placeholder="Invoice No" />
                        </Form.Item>

                        <Form.Item
                            label="Tax Faktur No"
                            name="TaxFakturNo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Tax Faktur No!',
                                },
                            ]}
                        >
                            <Input placeholder="Tax Faktur No" />
                        </Form.Item>

                        <Form.Item
                            label="Invoice Date"
                            name="InvoiceDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Invoice Date!',
                                },
                            ]}
                        >
                            <DatePicker className="w-full" />
                        </Form.Item>

                        <Form.Item
                            label="Invoice Due Date"
                            name="InvoiceDueDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Invoice DueDate!',
                                },
                            ]}
                        >
                            <DatePicker className="w-full" />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="Description"
                            className="lg:col-span-2"
                        >
                            <Input.TextArea placeholder="Description" />
                        </Form.Item>

                    </div>

                    <h1 className="text-xl font-semibold border-b-2 pb-1 mb-2">Payment Information</h1>
                    <div>

                        <InputModal
                            title="CUSTOMER"
                            label="Customer"
                            name="CustomerName"
                            dataSource={dataCustomer}
                            loading={isLoading}
                            columns={columnsCustomer}
                            onData={(values) => setSelectCustomer(values)}
                            onOpenModal={(values) => setOpenCustomer(values)}
                        />

                        <InputModal
                            title="ORDER"
                            label="Order"
                            name="OrderCode"
                            dataSource={dataOrder}
                            loading={isLoading}
                            columns={columnsOrder}
                            onData={(values) => setSelectOrder(values)}
                            onOpenModal={(values) => setOpenOrder(values)}
                        />

                        <Form.Item
                            label="Payment Status"
                            name="PaymentStatus"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Payment Status!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="All Invoice Status"
                                options={[
                                    {
                                        value: 'UNPAID',
                                        label: 'UNPAID',
                                    },
                                    {
                                        value: 'PENDING REVIEW',
                                        label: 'PENDING REVIEW',
                                    },
                                    {
                                        value: 'REVISION NEEDED',
                                        label: 'REVISION NEEDED',
                                    },
                                    {
                                        value: 'PAID',
                                        label: 'PAID',
                                    },
                                    {
                                        value: 'REJECT',
                                        label: 'REJECT',
                                    },
                                ]}
                            />
                        </Form.Item>

                        <TableInvoice dataSource={selectOrder} />

                    </div>

                    <div className="flex justify-end gap-2 mt-4">
                        <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
                        <Button htmlType="button" onClick={handleReset}>Reset</Button>
                    </div>

                </Form>
            </div>
        </>
    )
}

export default FormInvoice;


const columnsOrder = [
    {
        key: "OrderCode",
        title: "Order Code",
        dataIndex: "OrderCode",
        fixed: "left",
        // render: (text) => <Link to={`${text}`} className="text-indigo-600 hover:text-indigo-400 font-medium">{text}</Link>,
    },
    {
        key: "OrderQuotation",
        title: "Order Quotation",
        dataIndex: "OrderQuotation"
    },
    {
        key: "ReceivedDate",
        title: "Received Date",
        dataIndex: "ReceivedDate"
    },
    {
        key: "CustomerName",
        title: "Customer Name",
        dataIndex: "CustomerName"
    },
    {
        key: "SubmittedBy",
        title: "Submitted By",
        dataIndex: "SubmittedBy"
    },
    {
        key: "Priority",
        title: "Priority",
        dataIndex: "Priority"
    },
    {
        key: "PaymentStatus",
        title: "Payment Status",
        dataIndex: "PaymentStatus",
        render: (text) => {
            if (!text) {
                return "-"
            }
            return text
        }
    },
    {
        key: "Total",
        title: "Total",
        dataIndex: "Total",
        render(text) {
            return (
                <div className="flex justify-between">
                    <p>Rp</p> <p>{formatRupiah(text)}</p>
                </div>
            )
        }
    }
];


const columnsCustomer = [
    {
        title: "AccountID",
        dataIndex: "AccountID",
        key: "AccountID",
    },
    {
        title: "CustomerId",
        dataIndex: "CustomerId",
        key: "CustomerId",
    },
    {
        title: "CustomerName",
        dataIndex: "CustomerName",
        key: "CustomerName",
    },
    {
        title: "ContactPerson",
        dataIndex: "ContactPerson",
        key: "ContactPerson",
    },
    {
        title: "Address",
        dataIndex: "Address",
        key: "Address",
    },
    {
        title: "EmailContact",
        dataIndex: "EmailContact",
        key: "EmailContact",
    },
    {
        title: "Phone",
        dataIndex: "Phone",
        key: "Phone",
    },
    {
        title: "MobilePhone",
        dataIndex: "MobilePhone",
        key: "MobilePhone",
    },
    {
        title: "Fax",
        dataIndex: "Fax",
        key: "Fax",
    },
];
