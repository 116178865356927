import { DatePicker, Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect } from 'react';
import SampleCOADetail from './SampleCOADetail';
import dayjs from 'dayjs';
import TableWorksheet from './TableWorksheet';

const FormDetail = ({ onDataSource }) => {

    const dataSample = onDataSource.Sample;
    const dataWorksheet = onDataSource.Worksheet;
    const dataCOA = onDataSource.COA;

    const [form] = Form.useForm();

    const onsubmit = (values) => {
        console.log("Submit", values);
    };

    useEffect(() => {
        if (form && dataSample) {
            const payload = {
                ...dataSample,
                ReceivedDate: dayjs(dataSample.ReceivedDate),
                COADueDate: dayjs(dataSample.COADueDate)
            }
            form.setFieldsValue(payload);
        }
    }, [form, dataSample])

    return (
        <>
            <Form
                name="filterForm"
                layout="vertical"
                onFinish={onsubmit}
                autoComplete="off"
                form={form}
            >

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <Form.Item
                        label="Sample Name"
                        name="SampleName"
                    >
                        <Input placeholder="Sample Name" disabled />
                    </Form.Item>

                    <Form.Item
                        label="Sample Code"
                        name="SampleCode"
                    >
                        <Input placeholder="Sample Code" disabled />
                    </Form.Item>
                </div>

                <Form.Item
                    label="Description"
                    name="Description"
                >
                    <TextArea rows={4} placeholder="Desctiption" disabled />
                </Form.Item>


                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                    <Form.Item
                        label="Receive Date"
                        name="ReceivedDate"
                        className="w-full"
                    >
                        <DatePicker
                            onChange={(data, dataSting) => console.log(data, dataSting)}
                            className="w-full"
                            disabled
                        />
                    </Form.Item>

                    <Form.Item
                        label="COA Release Due Date"
                        name="COADueDate"
                        className="w-full"
                    >
                        <DatePicker
                            onChange={(data, dataSting) => console.log(data, dataSting)}
                            className="w-full"
                            disabled
                        />
                    </Form.Item>
                </div>

                <Form.Item
                    label="Result Summary"
                    name="ResultSummary"
                >
                    <TextArea rows={4} placeholder="Result Summary" maxLength={6} />
                </Form.Item>

            </Form>
            <div className="flex flex-col gap-10">
                <TableWorksheet onDataSource={dataWorksheet} />

                <SampleCOADetail onDataSource={dataCOA} />
            </div>

        </>
    )
}

export default FormDetail