import { DeleteFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { Button, message, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import { DelNews } from "../../API/DeleteData";

const { confirm } = Modal;

const DeleteNews = ({ onData, onDelete }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const res = await DelNews(onData.NewsCode);
      message.success(res.data.message);
      onDelete(true);
      Modal.destroyAll();
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
    }
    setIsLoading(false);
  };

  const content = `Data ${onData.TitleNews}..`;

  const showConfirm = () => {
    confirm({
      title: "Do you want to delete these items?",
      icon: <ExclamationCircleFilled />,
      content: content,
      centered: true,
      footer:
        <div className="flex justify-end gap-1">
          <Button onClick={() => Modal.destroyAll()} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button danger type="primary" onClick={handleDelete} loading={isLoading}>
            Delete
          </Button>
        </div>,
    });
  };

  return (
    <>
      <Tooltip title="Delete">
        <Button icon={<DeleteFilled />} onClick={showConfirm} type="text" />
      </Tooltip>
    </>
  );
};

export default DeleteNews;
