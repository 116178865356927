import React, { useEffect, useState } from 'react'
import HeaderTitle from '../../Components/HeaderTitle'
import { Link } from 'react-router-dom'
import { Button, Space, Table, Tabs } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import SearchInput from '../../Components/SearchInput'
import { getCustomerAccount } from '../../API/GetData'
import EditAccount from './edit'
import DeleteAccount from './delete'
import Users from './Users'
import CustomerCC from './CustomerCC'

const Account = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768); // State untuk mendeteksi mode desktop
    const [searchText, setSearchText] = useState('');

    const fetchData = async () => {
        try {
            const res = await getCustomerAccount();
            setData(res);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
        setLoading(false);
    }, []);

    // Effect untuk mendeteksi perubahan ukuran layar
    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some((val) =>
            val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const columns = [
        {
            title: "#",
            dataIndex: "key",
            key: "key",
            fixed: isDesktop ? "left" : false, // Fixed hanya di desktop
            width: 80
        },
        {
            title: "Account ID",
            dataIndex: "AccountID",
            key: "AccountID",
            fixed: isDesktop ? "left" : false // Fixed hanya di desktop
        },
        {
            title: "Customer Id",
            dataIndex: "CustomerId",
            key: "CustomerId"
        },
        {
            title: "Customer Name",
            dataIndex: "CustomerName",
            key: "CustomerName"
        },
        {
            title: "Contact Person",
            dataIndex: "ContactPerson",
            key: "ContactPerson"
        },
        {
            title: "Address",
            dataIndex: "Address",
            key: "Address",
            width: 400
        },
        {
            title: "Email Contact",
            dataIndex: "EmailContact",
            key: "EmailContact"
        },
        {
            title: "Phone",
            dataIndex: "Phone",
            key: "Phone"
        },
        {
            title: "Mobile Phone",
            dataIndex: "MobilePhone",
            key: "MobilePhone"
        },
        {
            title: "Fax",
            dataIndex: "Fax",
            key: "Fax"
        },
        {
            title: "Action",
            fixed: "right",
            width: isDesktop ? 100 : 85,
            render: (_, record) => (
                <Space>
                    <EditAccount onData={record} onEdit={fetchData} />
                    {/* <DeleteAccount onData={record} onDelete={fetchData} /> */}
                </Space>
            ),
        },
    ];

    return (
        <>
            <div className="flex items-center justify-between">
                <HeaderTitle title="CUSTOMER" subtitle="All data a Customer Account" />
                <Link to={'form'}>
                    <Button type="primary" icon={<PlusOutlined />}>Add New</Button>
                </Link>
            </div>

            <div className="bg-white p-4 rounded-md mt-4">
                <div className="flex justify-end mb-2">
                    <SearchInput value={searchText} onChange={handleSearch} />
                </div>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={filteredData}
                    expandable={{
                        expandedRowRender
                    }}
                    scroll={columns.length > 8 ? { x: 2000 } : undefined} // Scroll horizontal di desktop jika kolom banyak
                />

            </div>
        </>
    )
}

export default Account;

const expandedRowRender = (record) => {

    const items = [
        {
            key: '1',
            label: 'Customer Care',
            children: <CustomerCC dataSource={record.cc} />,
        },
        {
            key: '2',
            label: 'Users',
            children: <Users dataSource={record.users} />,
        },
    ];

    return (
        <Tabs defaultActiveKey="1" items={items} />
    )
}
