import { Button, Form, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'
import HeaderTitle from '../../Components/HeaderTitle'
import { RollbackOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import FormUsers from './Users/form'
import { postCustomerAccount, SendEmail } from '../../API/PostData'
import FormCustomerCC from './CustomerCC/form'
import { getCustomers } from '../../API/GetData'


const emailTemplate = (customerAccount, dataUser) => `
    <!DOCTYPE html>
    <html lang="id">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Process Order</title>
       <style>
                body { font-family: Arial, sans-serif; background-color: #f4f4f4; }
                .email-container { max-width: 600px; margin: 0 auto; padding: 20px; background-color: #fff; }
                .header { text-align: center; }
                .content { color: #333; }
                .order-info { font-weight: bold; color: #312E81; }
                .btn-download { background-color: #312E81; color: #fff; padding: 10px 20px; text-decoration: none; border-radius: 5px; cursor: pointer; }
                .footer { text-align: center; color: #777; }
            </style>
    </head>
    <body>
        <div class="email-container">
            <div class="header">
                <h2>Customer Registrasi</h2>
            </div>
            <div class="content">
                <p>Selamat Anda telah terdaftar sebagai customer di Sains Analitika Prima Lab, dengan data :</p>
                <p>Customer Name : ${customerAccount.CustomerName}</p>
                <p>Email Contact : ${customerAccount.EmailContact}</p>
                <p>Username : ${dataUser.Username}</p>
                <p>Password : ${dataUser.Password} (default)</p>
                
                <p>Silakan Akses 
                    <a href="https://sainsanalitikaprima.co.id/" target="_blank">
                        Customer Portal Kami
                    </a>, Untuk mengetahui lebih detail status Testing Order Anda.</p>

            </div>
            <div class="footer">
                <p>Salam,<br>Sains Analitika Prima Lab</p>
                <br>
                <p>Note: Silakan ubah Password Anda Pada Menu Profile Account untuk menjaga kerahasian data Anda.</p>
            </div>
        </div>
    </body>
    </html>
`;

const FormAccount = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [dataUsers, setDataUsers] = useState([]);
    const [dataCustomerCC, setDataCustomerCC] = useState([]);

    const [accountID, setAccountID] = useState("");

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const res = await getCustomers();
                // console.log(res);

                if (res.length > 0) {
                    const filteredData = res.filter(item => item.AccountID.startsWith("AC"));
                    if (filteredData.length > 0) {
                        const lastCode = filteredData[filteredData.length - 1].AccountID;
                        const nextNumber = parseInt(lastCode.substr(2)) + 1;
                        const nextCode = `AC${nextNumber.toString().padStart(3, "0")}`
                        setAccountID(nextCode);
                    } else {
                        setAccountID("AC001");
                    }
                } else {
                    setAccountID("AC001");
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchCustomers();
    }, []);

    useEffect(() => {
        if (form && accountID) {
            form.setFieldsValue({ AccountID: accountID });
            form.setFieldsValue({ CustomerId: "CU001" });
        }
    }, [accountID, form]);

    const handleSubmit = async (values) => {
        try {

            if (!dataUsers.length > 0) {
                message.warning("Complete data User!");
                return;
            }

            const payload = {
                ...values,
                customercc: dataCustomerCC,
                users: dataUsers,
            };
            const res = await postCustomerAccount(payload);
            if (res) {
                const dataUser = dataUsers[0];
                const payloadEmail = {
                    email: values ? values.EmailContact.toString() : "aditiaramadhan301101@gmail.com",
                    subject: `Customer Registrasi, Sains Analitika Prima Lab`,
                    message: "",
                    html: emailTemplate(values, dataUser),
                }
                const resEmail = await SendEmail(payloadEmail);
                console.log(resEmail);
            }

            message.success(res.data.message);
            // navigate('/dashboard/account');
        } catch (error) {
            console.log(error);
            message.error(error.response.data.message);
        }
    }

    const handleReset = () => {
        form.resetFields();
    }

    return (
        <>
            <div className="flex justify-between items-center">
                <HeaderTitle title="CUSTOMER ACCOUT" subtitle="Form a Customer Account" />
                <Button icon={<RollbackOutlined />} onClick={() => navigate(-1)}>Back</Button>
            </div>

            <div className="bg-white p-4 rounded-md mt-4">
                <Form
                    name="formOrder"
                    layout="vertical"
                    onFinish={handleSubmit}
                    autoComplete="off"
                    form={form}
                >

                    {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-4"> */}

                    <Form.Item
                        label="Account ID"
                        name="AccountID"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Account ID!',
                            },
                        ]}
                    >
                        <Input placeholder="Account ID" />
                    </Form.Item>

                    <Form.Item
                        label="Customer Id"
                        name="CustomerId"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Customer Id!',
                            },
                        ]}
                    >
                        <Input placeholder="Customer Id" />
                    </Form.Item>

                    <Form.Item
                        label="Customer Name"
                        name="CustomerName"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Customer Name!',
                            },
                        ]}
                    >
                        <Input placeholder="Customer Name" />
                    </Form.Item>

                    <Form.Item
                        label="Email Contact"
                        name="EmailContact"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Email Contact!',
                            },
                        ]}
                    >
                        <Input placeholder="Email Contact" />
                    </Form.Item>

                    <Form.Item
                        label="Contact Person"
                        name="ContactPerson"
                    >
                        <Input placeholder="Contact Person" />
                    </Form.Item>

                    <Form.Item
                        label="Address"
                        name="Address"
                    >
                        <Input placeholder="Address" />
                    </Form.Item>

                    <Form.Item
                        label="Phone"
                        name="Phone"
                    >
                        <Input placeholder="Phone" />
                    </Form.Item>

                    <Form.Item
                        label="Mobile Phone"
                        name="MobilePhone"
                    >
                        <Input placeholder="Mobile Phone" />
                    </Form.Item>

                    <Form.Item
                        label="Fax"
                        name="Fax"
                    >
                        <Input placeholder="Fax" />
                    </Form.Item>

                    {/* </div> */}
                    <div className="border rounded-md mb-4 p-4">
                        <FormCustomerCC onSaveData={(values) => setDataCustomerCC(values)} />
                    </div>

                    <div className="border rounded-md mb-4 p-4">
                        <FormUsers onSaveData={(values) => setDataUsers(values)} />
                    </div>

                    <div className="flex justify-end gap-2">
                        <Button type="primary" htmlType="submit">Submit</Button>
                        <Button htmlType="button" onClick={handleReset}>Reset</Button>
                    </div>

                </Form>
            </div>
        </>
    )
}

export default FormAccount