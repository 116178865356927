import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import SampleOrder from './SampleOrder';
import { Tabs } from 'antd';
import OrderTab from './OrderTab';
import { getOrder } from '../../../../API/GetData';

const DetailOrder = () => {
    const params = useParams();
    const [dataSource, setDataSource] = useState([]);

    const { accountid, code } = params;

    const fetchData = async () => {
        try {
            // const res = dataOrder.filter(item => item.OrderNumber === id);
            const res = await getOrder(accountid, code);
            // console.log(res);
            setDataSource(res[0]);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const items = [
        {
            key: '1',
            label: 'Order',
            children: <OrderTab onDataSource={dataSource.Order} />,
        },
        {
            key: '2',
            label: 'Sample',
            children: <SampleOrder onDataSource={dataSource.Sample} />,
        },
    ];

    return (
        <div className="bg-white p-4 rounded-md">
            <p className="text-xl pb-2">{code}</p>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    )
}

export default DetailOrder