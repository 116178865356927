import { Table } from 'antd';
import React from 'react'

const CustomerCC = ({ dataSource }) => {

  const data = dataSource.map((cc, index) => ({ ...cc, key: index + 1 }))

    const columns = [
        {
            title: "#",
            dataIndex: "key",
            key: "key",
        },
        {
            title: "Name",
            dataIndex: "CCName",
            key: "Name",
        },
        {
            title: "Email",
            dataIndex: "CCEmail",
            key: "Email",
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={data}
            scroll={columns.length > 8 ? { x: 2000 } : null}
        />
    )
}

export default CustomerCC