import { Table, Button } from 'antd';
import React, { useState } from 'react';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const Users = ({ dataSource }) => {
    
    const [showPassword, setShowPassword] = useState({});
    const toggleShowPassword = (key) => {
        setShowPassword(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    const data = dataSource.map((user, index) => ({ ...user, key: index + 1 }));

    const columns = [
        {
            title: "#",
            dataIndex: "key",
            key: "key",
        },
        {
            title: "Username",
            dataIndex: "Username",
            key: "Username",
        },
        {
            title: "Password",
            dataIndex: "Password",
            key: "Password",
            render: (text, row) => (
                <div className="flex items-center">
                    <p>{showPassword[row.key] ? text : "**********"}</p>
                    <Button
                        type="link"
                        onClick={() => toggleShowPassword(row.key)}
                    >
                        {showPassword[row.key] ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={data}
            scroll={columns.length > 8 ? { x: 2000 } : null}
        />
    );
};

export default Users;
