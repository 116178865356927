import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getNews } from '../../../API/GetData';
import { Button } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';

const DetailCarousel = () => {
    const { id } = useParams();
    const [dataBanner, setDataBanner] = useState([]);

    const fetchData = useCallback(async () => {
        try {
            const res = await getNews();
            const filterRes = res.filter((item) => item.NewsCode === id);
            setDataBanner(filterRes);
        } catch (error) {
            console.log(error);
        }
    }, [id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    console.log(dataBanner);

    return (
        <>
            <Link to={`/dashboard`} className="flex w-full justify-end mb-4">
                <Button icon={<RollbackOutlined />}>Back</Button>
            </Link>
            {dataBanner.length > 0 ? (
                dataBanner.map((item) => (
                    <div key={item.NewsCode} className="bg-white p-4 rounded-md">
                        <h1 className="text-center text-4xl font-bold">{item.TitleNews}</h1>
                        <div className="flex flex-col items-center justify-center mt-4 gap-4">
                            <img
                                src={`${process.env.REACT_APP_BASEURL_DOC}/${item.ImageNews}`}
                                alt={item.TitleNews}
                                className="w-full h-80 object-contain drop-shadow"
                            />
                            <p className="text-lg text-justify">{item.Description}</p>
                        </div>
                    </div>
                ))
            ) : (
                <p>No banner available</p>
            )}
        </>
    );
};

export default DetailCarousel;
