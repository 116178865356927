import React from 'react';
import Chart from 'react-google-charts';

const ChartTopYearSales = ({ dataSource }) => {

    const data = dataSource && dataSource.length > 0
        ? [
            ["CustomerName", "Paid", "TotalPayment", "Outstanding"],
            ...dataSource.map((item) => [
                item.CustomerName,
                Number(item.Paid),
                Number(item.TotalPayment),
                Number(item.Outstanding)
            ]
            )
        ]
        : [
            ["CustomerName", "Paid", "TotalPayment", "Outstanding"],
            ["No Data", 0, 0, 0], // Placeholder data jika tidak ada data
        ];

    const options = {
        title: 'Year Sales',
        // hAxis: { title: 'Customer Name' },
        // vAxis: { title: 'Value' },
        legend: { position: 'top' },
    };

    return (
        <div style={{ width: '100%', textAlign: 'center' }}> 
            {/* <h2>Sales Growth Chart</h2> */}
            <div style={{ width: '100%', height: '400px' }}>
                <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="100%"
                    data={data}
                    options={options}
                />
            </div>
        </div>
    );
}

export default ChartTopYearSales;
