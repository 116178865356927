import React from 'react'
import TableCOA from '../../../COA/TableCOA';

const SampleCOADetail = ({ onDataSource }) => {

  return (
    <div>
      <h1 className="border-b-2 pb-1 text-xl mb-2">List of COA</h1>
      <TableCOA isFilteredData={onDataSource} isPage={true} />
    </div>
  )
}

export default SampleCOADetail