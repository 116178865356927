import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import TableOrder from './TableOrder';
import { getOrderView } from '../../../API/GetData';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { AuthData } from '../../../Components/Global';

const { RangePicker } = DatePicker;

const Order = () => {
  const userData = AuthData();
  const [filterForm] = Form.useForm();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async (Date1, Date2, OrderCode) => {
    try {
      setLoading(true);
      const res = await getOrderView(Date1, Date2, OrderCode);
      setData(res);
      setFilteredData(res); // Initialize filteredData with fetched data
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onFilterData = async (values) => {
    setLoading(true);
    if (values.recived) {
      const Date1 = dayjs(values.recived[0]).format('YYYY-MM-DD')
      const Date2 = dayjs(values.recived[1]).format('YYYY-MM-DD')
      const OrderCode = values.OrderCode ? values.OrderCode : ''
      fetchData(Date1, Date2, OrderCode)
    }

    const filtered = data.filter((item) => {
      const OrderCodeMatch = values.OrderCode ? item.OrderCode.includes(values.OrderCode) : true;
      const paymentStatus = values.PaymentStatus && values.PaymentStatus !== 'AllPaymentStatus' ? item.PaymentStatus === values.PaymentStatus : true;
      const priorityMatch = values.Priority && values.Priority !== 'AllPriority' ? item.Priority === values.Priority : true;
      const receivedDateMatch = values.recived ? (
        new Date(item.ReceivedDate) >= values.recived[0] && new Date(item.ReceivedDate) <= values.recived[1]
      ) : true;
      const contactMatch = values.bysubmitted ? item.SubmittedBy.includes(values.bysubmitted) : true;
      
      return OrderCodeMatch &&
        paymentStatus &&
        priorityMatch &&
        receivedDateMatch &&
        contactMatch;
    });


    setFilteredData(filtered);
    setLoading(false);
  };

  const onResetFilter = () => {
    filterForm.resetFields();
    fetchData();
  };

  const handleClearStorage = () => {
    localStorage.removeItem('formOrderData');
    localStorage.removeItem('formSampleData');
  }


  return (
    <div className="bg-white p-4 rounded-md">

      <Form
        name="filterForm"
        layout="vertical"
        onFinish={onFilterData}
        autoComplete="off"
        form={filterForm}
        className="relative border-2 rounded-md p-4 my-4"
      >
        <p className="absolute -top-3 left-2 bg-white px-2 text-md">Filter Order</p>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">

          <Form.Item label="Search by Order Number" name="OrderCode">
            <Input placeholder="Search by Order Number" />
          </Form.Item>

          <Form.Item label="All Payment Status" name="PaymentStatus">
            <Select
              placeholder="All Payment Status"
              defaultValue="AllPaymentStatus"
              options={[
                { value: 'AllPaymentStatus', label: 'All Payment Status' },
                { value: 'Process', label: 'Process' },
                { value: 'PAID', label: 'PAID' },
              ]}
            />
          </Form.Item>

          <Form.Item label="All Priority" name="Priority">
            <Select
              placeholder="All Priority"
              defaultValue="AllPriority"
              options={[
                { value: 'AllPriority', label: 'All Priority' },
                { value: 'Normal', label: 'Normal' },
                { value: 'Very Urgent', label: 'Very Urgent' },
                { value: 'Urgent', label: 'Urgent' },
              ]}
            />
          </Form.Item>

          <Form.Item label="Recived" name="recived" className="w-full">
            <RangePicker className="w-full" />
          </Form.Item>

          <Form.Item label="Search by Submitted" name="bysubmitted">
            <Input placeholder="Search by Submitted" />
          </Form.Item>

        </div>

        <div className="flex justify-end gap-2">
          <Button type="primary" htmlType="submit">Submit</Button>
          <Button type="primary" htmlType="button" danger onClick={onResetFilter}>Cancel</Button>
        </div>
      </Form>

      {userData && (userData.Role === "Admin" || userData.Role === "SuperAdmin") && (
        <div className="flex justify-end my-4">
          <Link to={'form'}>
            <Button type="primary" icon={<PlusOutlined />} onClick={handleClearStorage}>Add New</Button>
          </Link>
        </div>
      )}

      <TableOrder isFilteredData={filteredData} isLoading={loading} />
    </div>
  )
}

export default Order;
