import React, { useEffect, useState } from 'react';
import { getInvoice } from '../../API/GetData';
import { useParams } from 'react-router-dom';
import { Table } from 'antd';
import { formatRupiah } from '../../Components/Global';

const InvoicePrint = () => {

    const params = useParams();
    const { accountid, code } = params;

    const [dataINV, setDataINV] = useState("");

    useEffect(() => {
        const fetchInvoice = async () => {
            try {
                const res = await getInvoice(accountid, code)
                setDataINV(res[0]);
            } catch (error) {
                console.log(error);
            }
        }
        fetchInvoice();
    }, [accountid, code]);

    useEffect(() => {
        if (dataINV) {
            window.print();
        }

    }, [dataINV]);

    const data = dataINV ? dataINV : "";

    const Total = data ? data.Total : 0;
    const Discount = data ? data.Discount : 0;
    const VAT = data ? data.VAT : 0;

    const TotalDiscount = Total * (Discount / 100);
    const DPP = Total - TotalDiscount;
    const TotalVAT = DPP * (VAT / 100);
    const NET = DPP + TotalVAT;

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white">
            {/* CSS for hiding header/footer on print */}
            <style>
                {`
          @media print {
            @page {
              margin: 0; /* Remove default browser margins */
            }
            body {
              margin: 1cm; /* Set custom margins for the printed content */
            }
            /* Hide the browser's header/footer */
            header, footer {
              display: none;
            }
          }
        `}
            </style>

            <div className="flex justify-between items-center mb-8">
                <div>
                    <h2 className="text-2xl font-bold">INVOICE NO</h2>
                    <p className="text-gray-600">{data.InvoiceNo}</p>
                </div>
                {/* <div className="text-right">
                    <h3 className="text-lg font-bold">CRMS</h3>
                    <p>123 Street Name</p>
                    <p>City, Country</p>
                    <p>Email: company@example.com</p>
                </div> */}
            </div>

            <div className="flex justify-between mb-8">
                <div>
                    <h4 className="font-bold">Billed To:</h4>
                    <p>{data.CustomerName}</p>
                    <p>{data.Address}</p>
                    <p>{data.EmailContact}</p>
                </div>
                <div className="text-right flex flex-col gap-2">
                    <div>
                        <h4 className="font-bold">Date of Issue:</h4>
                        <p>{data.InvoiceDate}</p>
                    </div>
                    <div>
                        <h4 className="font-bold">Payment Status:</h4>
                        <p>{data.PaymentStatus}</p>
                    </div>
                </div>
            </div>

            <Table
                columns={columns}
                dataSource={[data]}
                pagination={false}
                summary={() => {
                    return (
                        <>
                            {/* <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3}>
                                <p className="font-bold">Gross</p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                                <div className="flex justify-between">
                                    <p>Rp</p> <p>{formatRupiah(Total)}</p>
                                </div>
                            </Table.Summary.Cell>
                        </Table.Summary.Row> */}
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={3}>
                                    <p className="font-bold">Diskon ({data.Discount}%)</p>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <div className="flex justify-between">
                                        <p>Rp</p> <p>{formatRupiah(TotalDiscount)}</p>
                                    </div>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={3}>
                                    <p className="font-bold">DPP</p>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <div className="flex justify-between">
                                        <p>Rp</p> <p>{formatRupiah(DPP)}</p>
                                    </div>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={3}>
                                    <p className="font-bold">VAT ({VAT}%)</p>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <div className="flex justify-between">
                                        <p>Rp</p> <p>{formatRupiah(TotalVAT)}</p>
                                    </div>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={3}>
                                    <p className="font-bold">NET</p>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <div className="flex justify-between">
                                        <p>Rp</p> <p>{formatRupiah(NET)}</p>
                                    </div>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    )
                }}

            />

            {/* <div className="mt-8">
                <button
                    onClick={handlePrint}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    Print Invoice
                </button>
            </div> */}
        </div>
    );
};

export default InvoicePrint;

const columns = [
    {
        title: 'Order Code',
        dataIndex: 'OrderCode',
        key: 'OrderCode'
    },
    {
        title: 'Customer',
        dataIndex: 'CustomerName',
        key: 'Customer'
    },
    {
        title: 'Received Date',
        dataIndex: 'ReceivedDate',
        key: 'ReceivedDate'
    },
    {
        title: 'Value Order',
        dataIndex: 'Total',
        key: 'ValueOrder',
        render(text) {
            return (
                <div className="flex justify-between">
                    <p>Rp</p> <p>{formatRupiah(text ? text : 0)}</p>
                </div>
            )
        }
    },
];
