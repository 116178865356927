import React from 'react';
import Chart from 'react-google-charts';

const toRupiah = (num) => {
    if (typeof num !== "number") return num;
    return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
    }).format(num);
};

const ChartSalesYTD = ({ dataSource }) => {
    // Mengelompokkan data berdasarkan CustomerName dan menjumlahkan TotalSales serta SalesYTD
    const groupedData = dataSource && dataSource.length > 0
        ? Object.values(
            dataSource.reduce((acc, item) => {
                const { CustomerName, Year, TotalSales, SalesYTD } = item;
                if (!acc[CustomerName]) {
                    acc[CustomerName] = {
                        CustomerName,
                        TotalSales: Number(TotalSales),
                        SalesYTD: Number(SalesYTD),
                        years: [Year.toString()],
                    };
                } else {
                    acc[CustomerName].TotalSales += Number(TotalSales);
                    acc[CustomerName].SalesYTD += Number(SalesYTD);
                    acc[CustomerName].years.push(Year.toString());
                }
                return acc;
            }, {})
        )
        : [];

    // Data untuk grafik setelah dikelompokkan
    const data = groupedData.length > 0
        ? [
            ["CustomerName", "TotalSales", { role: "tooltip", type: "string" }, "SalesYTD", { role: "tooltip", type: "string" }],
            ...groupedData.map((item) => [
                item.CustomerName,
                item.TotalSales,
                `Customer: ${item.CustomerName}\nTotal Sales: ${toRupiah(item.TotalSales)}\nYears: ${item.years.join(", ")}`,
                item.SalesYTD,
                `Customer: ${item.CustomerName}\nSales YTD: ${toRupiah(item.SalesYTD)}\nYears: ${item.years.join(", ")}`,
            ]),
        ]
        : [
            ["CustomerName", "TotalSales", { role: "tooltip", type: "string" }, "SalesYTD", { role: "tooltip", type: "string" }],
            ["No Data", 0, "No Data", 0, "No Data"],
        ];

    const options = {
        title: "Sales YTD",
        legend: { position: "top" },
        hAxis: {
            title: 'Customer Name',
        },
        bar: { groupWidth: '75%' },
        isStacked: true,
    };

    return (
        <div style={{ width: '100%', textAlign: 'center' }}>
            <div style={{ width: '100%', height: '400px' }}>
                <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="100%"
                    data={data}
                    options={options}
                />
            </div>
        </div>
    );
};

export default ChartSalesYTD;
