import React, { useEffect, useState } from 'react'
import { getCustomerDetail } from '../../API/GetData';
import { AuthData } from '../../Components/Global';
import { Button, Form, Input, message } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import HeaderTitle from '../../Components/HeaderTitle';
import { UpdateProfile } from '../../API/UpdateData';

const Profile = () => {
    const navigate = useNavigate();

    const [formCustomer] = Form.useForm();
    const [Customer, setCustomer] = useState([]);

    const [formUser] = Form.useForm();
    const userData = AuthData();

    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const res = await getCustomerDetail();
                const filterRes = res.filter((item) => item.AccountID === userData.AccountID);
                setCustomer(filterRes[0]);
            } catch (error) {
                console.log(error);
            }
        }

        fetchCustomer();
    }, [userData.AccountID]);
    // console.log(Customer);

    useEffect(() => {
        if (formCustomer) {
            formCustomer.setFieldsValue({ ...Customer, ...userData });
        }
    }, [Customer, formCustomer, userData]);

    const handleSubmitCustomer = async (values) => {
        try {
            const payload = {
                ...values,
                AccountID: userData.AccountID,
                CustomerId: Customer.CustomerId,
                UserCode: userData.UserCode
            };
            // console.log(payload);
            const res = await UpdateProfile(payload);
            message.success(res.data.message);
        } catch (error) {
            console.log(error);
            message.error(error.response.data.message);
        }
    }


    return (
        <>
            <div className="flex justify-between items-center">
                <HeaderTitle title="PROFILE" />
                <Button icon={<RollbackOutlined />} onClick={() => navigate(-1)}>Back</Button>
            </div>

            <Form
                name="formOrder"
                layout="vertical"
                onFinish={handleSubmitCustomer}
                autoComplete="off"
                form={formCustomer}
            >
                <div className="bg-white p-4 rounded-md mt-4">

                    <p className="text-xl font-bold mb-4 border-b-2 pb-2">Customer Data</p>
                    {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-4"> */}

                    <Form.Item
                        label="Customer Name"
                        name="CustomerName"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your CustomerName!',
                            },
                        ]}
                    >
                        <Input placeholder="CustomerName" />
                    </Form.Item>

                    <Form.Item
                        label="Contact Person"
                        name="ContactPerson"
                    >
                        <Input placeholder="ContactPerson" />
                    </Form.Item>

                    <Form.Item
                        label="Address"
                        name="Address"
                    >
                        <Input placeholder="Address" />
                    </Form.Item>

                    <Form.Item
                        label="Email Contact"
                        name="EmailContact"
                    >
                        <Input placeholder="EmailContact" />
                    </Form.Item>

                    <Form.Item
                        label="Phone"
                        name="Phone"
                    >
                        <Input placeholder="Phone" />
                    </Form.Item>

                    <Form.Item
                        label="Mobile Phone"
                        name="MobilePhone"
                    >
                        <Input placeholder="MobilePhone" />
                    </Form.Item>

                    <Form.Item
                        label="Fax"
                        name="Fax"
                    >
                        <Input placeholder="Fax" />
                    </Form.Item>

                    {/* </div> */}
                </div>

                <div className="bg-white p-4 rounded-md mt-4">

                    <p className="text-xl font-bold mb-4 border-b-2 pb-2">Account</p>
                    {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-4"> */}

                    <Form.Item
                        label="Username"
                        name="Username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Username!',
                            },
                        ]}
                    >
                        <Input placeholder="Username" />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="Password"
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>

                    {/* </div> */}
                </div>

                <div className="flex gap-2 mt-4">
                    <Button type="primary" htmlType="submit">Submit</Button>
                    {/* <Button htmlType="button" onClick={handleReset}>Reset</Button> */}
                </div>

            </Form>
        </>
    )
}

export default Profile