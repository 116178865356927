import { PlusOutlined } from '@ant-design/icons';
import { Button, Image, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { getNews } from '../../API/GetData';
import HeaderTitle from '../../Components/HeaderTitle';
import { Link } from 'react-router-dom';
import SearchInput from '../../Components/SearchInput';
import EditNews from './edit';
import DeleteNews from './delete';

const News = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchText, setSearchText] = useState('');

  const fetchData = async () => {
    try {
      const res = await getNews();
      setData(res);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    setLoading(false);
  }, []);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((val) =>
      val && val.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Image",
      dataIndex: "ImageNews",
      key: "ImageNews",
      render: (data) =>
        <Image
          width={140}
          src={`${process.env.REACT_APP_BASEURL_DOC}/${data}`}
        />
    },
    {
      title: "News Code",
      dataIndex: "NewsCode",
      key: "NewsCode",
    },
    {
      title: "Title News",
      dataIndex: "TitleNews",
      key: "TitleNews",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      width: 300,
    },
    {
      title: "Action",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space>
          <EditNews onData={record} onEdit={fetchData} />
          <DeleteNews onData={record} onDelete={fetchData} />
        </Space>
      ),
    },
  ];

  return (

    <>
      <div className="flex items-center justify-between">
        <HeaderTitle title="NEWS" subtitle="All data a News" />
        <Link to={'form'}>
          <Button type="primary" icon={<PlusOutlined />}>Add New</Button>
        </Link>
      </div>

      <div className="bg-white p-4 rounded-md mt-4">
        <div className="flex justify-end mb-2">
          <SearchInput value={searchText} onChange={handleSearch} />
        </div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={filteredData}
          scroll={
            columns.length > 8 && { x: 2000 }
          }
        />

      </div>
    </>
  );
}

export default News