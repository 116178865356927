import React, { useEffect, useState } from 'react'
import { getInvoiceView } from '../../API/GetData';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import TableInvoice from './TableInvoice';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { AuthData } from '../../Components/Global';

const { RangePicker } = DatePicker;

const Invoice = () => {
    const userData = AuthData();
    const [filterForm] = Form.useForm();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        try {
            const response = await getInvoiceView();
            setData(response);
            setFilteredData(response);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const onFilterData = async (values) => {
        const { InvoiceNo, OrderCode, PaymentStatus, InvoiceDate, InvoiceSentDate } = values;
        try {
            setIsLoading(true);
            let filtered = data;

            if (InvoiceNo) {
                filtered = filtered.filter(item =>
                    item.InvoiceNo.toLowerCase().includes(InvoiceNo.toLowerCase())
                )
            }

            if (OrderCode) {
                filtered = filtered.filter(item =>
                    item.OrderCode.toLowerCase().includes(OrderCode.toLowerCase())
                );
            }

            if (PaymentStatus && PaymentStatus !== 'AllInvoiceStatus') {
                filtered = filtered.filter(item =>
                    item.PaymentStatus === PaymentStatus
                );
            }

            if (InvoiceDate) {
                const [start, end] = InvoiceDate;
                filtered = filtered.filter(item =>
                    new Date(item.InvoiceDate) >= start.toDate() && new Date(item.InvoiceDate) <= end.toDate()
                );
            }

            if (InvoiceSentDate) {
                const [start, end] = InvoiceSentDate;
                filtered = filtered.filter(item =>
                    new Date(item.InvoiceSentDate) >= start.toDate() && new Date(item.InvoiceSentDate) <= end.toDate()
                );
            }

            setFilteredData(filtered);

        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    const onResetFilter = () => {
        filterForm.resetFields();
        // setFilteredData(data);
    };

    return (
        <div className="bg-white p-4 rounded-md">
            <h1 className="w-full border-b-2 text-lg pb-2">List of Sample</h1>
            <Form
                name="filterForm"
                layout="vertical"
                onFinish={onFilterData}
                autoComplete="off"
                form={filterForm}
                className="relative border-2 rounded-md p-4 my-4"
            >
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">

                    <Form.Item
                        label="Search by Invoice No"
                        name="InvoiceNo"
                    >
                        <Input placeholder="Search by Invoice No" />
                    </Form.Item>

                    <Form.Item
                        label="Search by Order Code"
                        name="OrderCode"
                    >
                        <Input placeholder="Search by Order Code" />
                    </Form.Item>

                    <Form.Item
                        label="All Status"
                        name="PaymentStatus"
                    >
                        <Select
                            placeholder="All Invoice Status"
                            defaultValue="AllInvoiceStatus"
                            options={[
                                {
                                    value: 'AllInvoiceStatus',
                                    label: 'All Invoice Status',
                                },
                                {
                                    value: 'Process',
                                    label: 'Process',
                                },
                                {
                                    value: 'Sent to Customer',
                                    label: 'Sent to Customer',
                                },
                                {
                                    value: 'PAID',
                                    label: 'PAID',
                                },
                                {
                                    value: 'Outstanding',
                                    label: 'Outstanding',
                                },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Invoice Date"
                        name="InvoiceDate"
                        className="w-full"
                    >
                        <RangePicker className="w-full" />
                    </Form.Item>

                    <Form.Item
                        label="Sent Date"
                        name="InvoiceSentDate"
                        className="w-full"
                    >
                        <RangePicker className="w-full" />
                    </Form.Item>


                </div>

                <div className="flex justify-end gap-2">
                    <Button type="primary" htmlType="submit">Submit</Button>
                    <Button type="primary" htmlType="submit" danger onClick={onResetFilter}>Cancel</Button>
                </div>
                
            </Form>

            {userData && (userData.Role === "Admin" || userData.Role === "SuperAdmin") && (
                <div className="flex justify-end my-4">
                    <Link to={'form'}>
                        <Button type="primary" icon={<PlusOutlined />}>Add New</Button>
                    </Link>
                </div>

            )}

            <TableInvoice isFilteredData={filteredData} isLoading={isLoading} />

        </div>
    )
}

export default Invoice