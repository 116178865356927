import React from 'react'
import TableSample from '../../Sample/TableSample';

const SampleOrder = ({ onDataSource }) => {
  return (
    <>
      <TableSample isFilteredData={onDataSource} isPage="Order" />
    </>
  )
}

export default SampleOrder