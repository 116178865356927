import { Table } from 'antd'
import dayjs from 'dayjs';
import React from 'react'
import { Link, useParams } from 'react-router-dom';

const TableSample = ({ isFilteredData, isLoading, isPage }) => {

    const params = useParams();
    const { accountid } = params;

    const columns = [
        {
            title: 'Sample Code',
            dataIndex: 'SampleCode',
            key: 'SampleCode',
            fixed: "left",
            sorter: (a, b) => a.SampleCode.localeCompare(b.SampleCode),
            render: (text, record) => <Link to={`/dashboard/menuorder/sample/${record.AccountID ? record.AccountID : accountid}/${text}`} className="text-indigo-600 hover:text-indigo-400 font-medium">{text}</Link>,
        },
        {
            title: 'Sample Name',
            dataIndex: 'SampleName',
            key: 'SampleName',
            sorter: (a, b) => a.SampleName.localeCompare(b.SampleName),
        },
        {
            title: 'Customer Name',
            dataIndex: 'CustomerName',
            key: 'CustomerName',
            sorter: (a, b) => a.CustomerName.localeCompare(b.CustomerName),
        },
        {
            title: 'Received Date',
            dataIndex: 'ReceivedDate',
            key: 'ReceivedDate',
            sorter: (a, b) => new Date(a.ReceivedDate) - new Date(b.ReceivedDate),
        },
        {
            title: 'COA Due Date',
            dataIndex: 'COADueDate',
            key: 'COADueDate',
            sorter: (a, b) => new Date(a.COADueDate) - new Date(b.COADueDate),
        },
    ];

    if (!isPage) {
        const sampleCodeIndex = columns.findIndex(column => column.key === 'SampleCode');
        const sampleCustName = columns.findIndex(column => column.key === 'CustomerName');

        columns.splice(sampleCodeIndex + 1, 0,
            {
                title: 'Order Code',
                dataIndex: 'OrderCode',
                key: 'OrderCode',
                sorter: (a, b) => a.OrderCode.localeCompare(b.OrderCode),
                render: (text, record) => <Link to={`/dashboard/menuorder/order/${record.AccountID}/${text}`} className="text-indigo-600 hover:text-indigo-400 font-medium">{text}</Link>,
            }
        );
        columns.splice(sampleCustName + 2, 0,
            {
                title: 'Priority',
                dataIndex: 'Priority',
                key: 'Priority',
                sorter: (a, b) => a.Priority.localeCompare(b.Priority),
            }
        )
        columns.push(
            {
                title: 'Publish Date',
                dataIndex: 'PublishDate',
                key: 'PublishDate',
                sorter: (a, b) => new Date(a.PublishDate) - new Date(b.PublishDate),
                render: (text) =>
                    text ? <p>{dayjs(text).format("YYYY-MM-DD")}</p> : "-"
            },
            {
                title: 'Status',
                dataIndex: 'Status',
                key: 'Status',
                sorter: (a, b) => a.Status.localeCompare(b.Status),
                render: (text) =>
                    text ? <p>{text}</p> : "-"
            },
        )
    }

    if (isPage) {
        columns.push(
            {
                title: 'Result Summary',
                dataIndex: 'ResultSummary',
                key: 'ResultSummary',
            },
            {
                title: 'Description',
                dataIndex: 'Description',
                key: 'Description',
                width: 250
            }
        )
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={isFilteredData}
                loading={isLoading}
                scroll={{
                    x: 1600,
                }}
            />
        </>
    )
}

export default TableSample