import { Table } from 'antd'
import React from 'react'

const TableWorksheet = ({ onDataSource }) => {

  const columns = [
    {
      title: 'Worksheet Code',
      dataIndex: 'WorksheeCode',
      key: 'WorksheeCode',
    },
    {
      title: 'Parameter Code',
      dataIndex: 'ParCode',
      key: 'ParCode',
    },
    // {
    //   title: 'Parameter Name',
    //   dataIndex: 'ParName',
    //   key: 'ParName',
    // },
    {
      title: 'Methode',
      dataIndex: 'Methode',
      key: 'Methode',
    },
    {
      title: 'Result',
      dataIndex: 'Result',
      key: 'Result',
    },
    {
      title: 'Unit',
      dataIndex: 'Unit',
      key: 'Unit',
    },
    {
      title: 'Min',
      dataIndex: 'Min',
      key: 'Min',
    },
    {
      title: 'Max',
      dataIndex: 'Max',
      key: 'Max',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
    },
  ];
  

  return (
    <div>
      <h1 className="border-b-2 pb-1 text-xl mb-2">List of Worksheet</h1>
      <Table
        columns={columns}
        dataSource={onDataSource}
        pagination={false}
        scroll={{
          x: 1200,
        }}
      />
    </div>
  )
}

export default TableWorksheet