import React, { useEffect, useState } from 'react';
import { Form, Input, Popconfirm, Table, Typography, DatePicker, Button, Modal } from 'antd';

import { CloseOutlined, DeleteOutlined, EditFilled, SaveFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ImportExcel from './ImportExcel';

const { TextArea } = Input;
const { Title } = Typography;
const dateFormat = 'YYYY-MM-DD';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    Vendor,
    ...restProps
}) => {

    return (
        <td {...restProps}>
            {editing ? (
                <div>

                    {dataIndex === 'Description' ? (
                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                        >
                            <TextArea rows={4} placeholder={title} />
                        </Form.Item>

                    ) : (

                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: `Please Input ${title}!`,
                        //     },
                        // ]}
                        >
                            {dataIndex === 'DocExpDate' && editing ? (

                                <DatePicker
                                    format={dateFormat}
                                />

                            ) : (

                                <Input placeholder={title} maxLength={50} />

                            )}
                        </Form.Item>
                    )}

                </div>
            ) : (
                children
            )
            }
        </td >
    );
};


const FormTableWorkSheet = ({ onSaveData, onEditData }) => {

    // console.log(onEditData);


    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    const [importData, setImportData] = useState([]);

    const [editingKey, setEditingKey] = useState('');
    // const [loading, setLoading] = useState(false);
    // const [isDisable, setIsDisable] = useState(true);

    useEffect(() => {
        if (onEditData.length > 0) {
            setData(onEditData);
            setCount(onEditData.length === 0 ? 0 : onEditData.map((item) => item.key)[0]);
            onSaveData(onEditData);
        } else if (onEditData.length < 0) {
            setData([]);
            setCount(0);
            onSaveData([]);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onEditData]);

    useEffect(() => {
        // const storedData = localStorage.getItem('dataExcel');
        if (importData) {
            // const dataExcel = JSON.parse(storedData);
            const res = importData.map((row, index) => (
                {
                    // ...row,
                    key: 1 + index,
                    WorksheeCode: `WC${(1 + index).toString().padStart(3, '0')}`,
                    ParCode: row.Parameter,
                    // ParName: row.Parameter,
                    Method: row.Method,
                    Result: "",
                    Unit: "",
                    Min: 0,
                    Max: 0,
                    Status: ""
                })).reverse();

            setData(res);
            setCount(res.length === 0 ? 0 : res.map((item) => item.key)[0]);
            onSaveData(res);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importData]);

    // console.log("Import", importData);


    const isEditing = (record) => record.key === editingKey;

    const handleEdit = (record) => {
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record.key);
    };

    const handleDelete = (key) => {
        // setIsDisable(false);
        const deletedRow = data.find((row) => row.key === key);
        const deletedNumber = deletedRow.key;
        const deletedkey = deletedRow.key;

        const newData = data.filter((row) => row.key !== key);

        const updatedData = newData.map((row) => {

            if (row.key > deletedNumber && row.key > deletedkey) {
                return { ...row, key: row.key - 1 };
            }
            return row;
        });

        setCount(updatedData.length > 0 ? updatedData[0].key : 0);

        setData(updatedData);
        onSaveData(updatedData);

        // console.log("DataFormTran", updatedData);
    };


    const handleCancel = (record) => {
        if (!record.WorksheeCode) {
            const newData = data.filter((item) => item.key !== record.key);
            setData(newData);
        } else {
            setEditingKey('');
        }
        setEditingKey('');

        // console.log("DataFormTran", data);
    };


    const handleSave = async (record) => {
        // setIsDisable(false);
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => record.key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
                onSaveData(newData);
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
                console.log("DataFormTran", newData);
                onSaveData(newData);
            }

            const editedRow = data.find((row) => row.key === record.key);
            const lastNumber = editedRow.key;
            // console.log(lastNumber);

            setCount(lastNumber);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };



    const handleAdd = () => {

        const num = count + 1;
        const code = (count + 1).toString().padStart(3, '0');



        // if (editingKey) {
        //     enqueueSnackbar("Complete the input form !", { variant: "warning" });
        //     return; // Stop saving if duplicate found
        // }

        const newData = {
            key: num,
            WorksheeCode: `WC${code}`,
            ParCode: "",
            // ParName: "",
            Method: "",
            Result: "",
            Unit: "",
            Min: 0,
            Max: 0,
            Status: "",
        };
        setData([newData, ...data]);
        handleEdit(newData);

        // console.log("DataFormTran", data);
    };

    // const handleSaveAllData = async () => {
    //     setLoading(true);
    //     setIsDisable(true);
    //     try {
    //         onSaveData(data);
    //         console.log("PostData", data);
    //         // enqueueSnackbar("Success add form table data!!", { variant: "success" });
    //     } catch (error) {
    //         console.log(error);
    //     }
    //     setLoading(false);
    // }

    // const handleCancelAllData = () => {
    //     setData([]);
    //     setCount(0);
    //     onSaveData([]);
    // }

    const columns = [
        // {
        //     title: 'No',
        //     dataIndex: 'key',
        //     sorter: (a, b) => a.key - b.key,
        //     fixed: 'left',
        //     width: 80,
        // },
        {
            title: 'Worksheet Code',
            dataIndex: 'WorksheeCode',
            key: 'WorksheeCode',
            editable: true,
        },
        {
            title: 'Parameter Code',
            dataIndex: 'ParCode',
            key: 'ParCode',
            editable: true,
        },
        // {
        //     title: 'Parameter Name',
        //     dataIndex: 'ParName',
        //     key: 'ParName',
        //     editable: true,
        // },
        {
            title: 'Method',
            dataIndex: 'Method',
            key: 'Method',
            editable: true,
        },
        {
            title: 'Result',
            dataIndex: 'Result',
            key: 'Result',
            editable: true,
        },
        {
            title: 'Unit',
            dataIndex: 'Unit',
            key: 'Unit',
            editable: true,
        },
        {
            title: 'Min',
            dataIndex: 'Min',
            key: 'Min',
            editable: true,
        },
        {
            title: 'Max',
            dataIndex: 'Max',
            key: 'Max',
            editable: true,
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            editable: true,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            width: 100,
            fixed: 'right',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleSave(record)} style={{ fontSize: '18px' }}>
                            <SaveFilled />
                        </Typography.Link>

                        <Typography.Link onClick={() => handleCancel(record)} style={{ fontSize: '18px' }}>
                            <CloseOutlined />
                        </Typography.Link>
                    </span>
                ) : (
                    <span style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                        <Typography.Link onClick={() => handleEdit(record)} style={{ fontSize: '18px' }}>
                            <EditFilled />
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                            <Link>
                                <DeleteOutlined style={{ fontSize: '18px' }} />
                            </Link>
                        </Popconfirm>
                    </span>
                );
            },
        }
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            onCell: (record) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
            ...col,
        };
    });

    return (

        <div className="border rounded-md p-4 mb-4">
            <Form form={form} component={false}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: "0 10px 10px"
                    }}
                >
                    <Title level={3} style={{ margin: "0" }}>
                        WorkSheet
                    </Title>
                    <div className="flex gap-2">
                        <ImportExcel onImportData={(values) => setImportData(values)} />
                        <Button
                            onClick={handleAdd}
                            type="primary"
                        // disabled={!!editingKey || !FacilityCode || !WONumber}
                        >
                            + Add Data
                        </Button>
                    </div>
                </div>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    // bordered
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={false}
                    scroll={{ x: 2000 }}
                />
                {/* {!onApproval && (
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-end",
                        margin: "10px 10px 0"
                    }}
                >
                    <Popconfirm title="Sure to clear all data?" onConfirm={handleCancelAllData}
                    >
                        <Button
                            disabled={!!editingKey || !!isDisable}
                        >
                            <span>Cancel</span>
                        </Button>
                    </Popconfirm>
                    <Button
                        type="primary"
                        onClick={handleSaveAllData}
                        loading={loading}
                        disabled={!!editingKey || !!isDisable}
                    >
                        <span>Save</span>
                    </Button>

                </div>
            )} */}
            </Form>
        </div>
    );
};
export default FormTableWorkSheet;