import React, { useCallback, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';

const { Dragger } = Upload;

const UploadFile = ({ onFiles }) => {
    const [fileList, setFileList] = useState([]);

    const createAndSendFormData = useCallback((files) => {
        const formData = new FormData();
        files.forEach(file => {
            formData.append('fileUploads', file.originFileObj);
        });

        if (onFiles) {
            onFiles(formData);
        }
    }, [onFiles]);

    const handleChange = useCallback((info) => {
        let updatedFileList = info.fileList.map(file => {
            if (file.type === 'application/pdf') {
                // Set file status to 'done' for PDFs
                return { ...file, status: 'done' };
            }
            return file;
        });

        setFileList(updatedFileList);

        // Create and send FormData whenever files change
        createAndSendFormData(updatedFileList);

        // Show messages only for valid PDF files
        const { status } = updatedFileList;
        if (status === 'done' && info.file.type === 'application/pdf') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }, [createAndSendFormData]);

    const beforeUpload = (file) => {
        const isPDF = file.type === 'application/pdf';
        if (!isPDF) {
            message.error('Only PDF files can be uploaded!');
            return Upload.LIST_IGNORE; // Ignore non-PDF files
        }
        return true; // Allow PDF files
    };

    return (
        <>
            <Dragger
                name="file"
                multiple={true}
                fileList={fileList}
                onChange={handleChange}
                beforeUpload={beforeUpload}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag PDF files to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for single or bulk upload of PDF files only.
                </p>
            </Dragger>
        </>
    );
};

export default UploadFile;
