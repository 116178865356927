import { Button, Form, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'
import HeaderTitle from '../../Components/HeaderTitle'
import { RollbackOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { postAccountAdmin } from '../../API/PostData'
import { getAccountAdmin } from '../../API/GetData'

const FormAccountAdmin = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [userCode, setUserCode] = useState("");

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const res = await getAccountAdmin();
                if (res.length > 0) {
                    const filteredData = res.filter(user => user.UserCode.startsWith("UC"));
                    if (filteredData.length > 0) {
                        const lastCode = filteredData[filteredData.length - 1].UserCode;
                        const nextNumber = parseInt(lastCode.substr(2)) + 1;
                        const nextCode = `UC${nextNumber.toString().padStart(3, "0")}`
                        setUserCode(nextCode)
                    } else {
                        setUserCode("UC001");
                    }
                } else {
                    setUserCode("UC001");
                }
            } catch (error) {
                setUserCode("UC001");
                console.log("Error fetching users:", error);
            }
        }
        fetchUsers();
    }, []);

    useEffect(() => {
        if (form && userCode) {
            form.setFieldsValue({ UserCode: userCode })
        }
    }, [form, userCode]);


    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            const payload = {
                ...values,
                AccountID: "Admin",
                CustomerId: "Admin",
                Role: "Admin"
            };
            const res = await postAccountAdmin(payload);
            message.success(res.data.message);
            navigate('/dashboard/account_admin');
        } catch (error) {
            console.log(error);
            message.error(error.response.data.message);
        }
        setLoading(false);
    }

    const handleReset = () => {
        form.resetFields();
    }

    return (
        <>
            <div className="flex justify-between items-center">
                <HeaderTitle title="ADMIN ACCOUNT" subtitle="Form a Admin Account" />
                <Button icon={<RollbackOutlined />} onClick={() => navigate(-1)}>Back</Button>
            </div>

            <div className="bg-white p-4 rounded-md mt-4">
                <Form
                    name="formOrder"
                    layout="vertical"
                    onFinish={handleSubmit}
                    autoComplete="off"
                    form={form}
                >

                    {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-4"> */}

                    <Form.Item
                        label="User Code"
                        name="UserCode"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your UserCode!',
                            },
                        ]}
                    >
                        <Input placeholder="UserCode" />
                    </Form.Item>

                    <Form.Item
                        label="Username"
                        name="Username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Username!',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Username"
                            onKeyPress={(e) => {
                                if (e.key === ' ') {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    {/* </div> */}

                    <div className="flex justify-end gap-2">
                        <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
                        <Button htmlType="button" onClick={handleReset}>Reset</Button>
                    </div>

                </Form>
            </div>
        </>
    )
}

export default FormAccountAdmin
