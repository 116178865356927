import React, { useEffect, useState } from 'react';
import { Button, Space, Table } from 'antd';
import DetailFormSample from './DetailFormSample';
import { EditFilled, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const TableSample = ({ onData }) => {

    const [OpenModal, setOpenModal] = useState(false);
    const [dataEdit, setDataEdit] = useState("");

    // console.log("DATA EDIT", dataEdit);


    const columns = [
        {
            title: 'No',
            dataIndex: 'key',
            key: 'key',
            width: 80,
            fixed: 'left'
        },
        {
            title: 'Sample Code',
            dataIndex: 'SampleCode',
            key: 'SampleCode',
            fixed: 'left'
        },
        {
            title: 'Sample Name',
            dataIndex: 'SampleName',
            key: 'SampleName'
        },
        {
            title: 'Received Date',
            dataIndex: 'ReceivedDate',
            key: 'ReceivedDate',
            render: (text) => <p>{dayjs(text).format('YYYY-MM-DD')}</p>
        },
        {
            title: 'COA Due Date',
            dataIndex: 'COADueDate',
            key: 'COADueDate',
            render: (text) => <p>{dayjs(text).format('YYYY-MM-DD')}</p>
        },
        {
            title: 'Action',
            key: 'action',
            fixed: "right",
            width: 100,
            render: (_, record) => {
                return (
                    <Space>
                        <Link to={`/dashboard/menuorder/order/sample/form?sampleCode=${record.SampleCode}`}>
                            <Button icon={<EditFilled />} type="text" />
                        </Link>
                    </Space>
                )
            }
        }
    ];

    const [dataSample, setDataSample] = useState([]);

    const fetchDataFromLocalStorage = () => {
        const storedData = localStorage.getItem('formSampleData');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            const formatData = parsedData.map((row, index) => ({ ...row, key: index + 1 }))
            // const formattedData = Array.isArray(parsedData) ? parsedData : [parsedData];
            setDataSample(formatData);
            // console.log('Retrieved Data', formattedData);
        }
    };

    useEffect(() => {
        fetchDataFromLocalStorage();
    }, []);

    return (
        <div className="border rounded-md p-4 mb-4">
            <div className="flex justify-between my-4">
                <h1 className="text-2xl font-medium">Sample</h1>
                {/* <Link to={`/dashboard/menuorder/order/sample/form`}> */}
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => onData(true)}
                >
                    Add Data
                </Button>
                {/* </Link> */}
            </div>
            <Table
                columns={columns}
                dataSource={dataSample}
                pagination={false}
                expandable={{
                    expandedRowRender
                }}
                scroll={{
                    x: 1000
                }}
            />
        </div>
    );
};
export default TableSample;


const expandedRowRender = (record) => {

    const dataWS = record.sampleWS;

    return (
        <Table
            columns={columnsWS}
            dataSource={dataWS}
            pagination={false}
            scroll={{
                x: 1000
            }}
        />
    )
}

const columnsWS = [
    {
        title: 'WorksheeCode',
        dataIndex: 'WorksheeCode',
        key: 'WorksheeCode',
        fixed: "left",
        width: 150,
    },
    {
        title: 'ParCode',
        dataIndex: 'ParCode',
        key: 'ParCode',
    },
    {
        title: 'ParName',
        dataIndex: 'ParName',
        key: 'ParName',
    },
    {
        title: 'Methode',
        dataIndex: 'Methode',
        key: 'Methode',
    },
    {
        title: 'Result',
        dataIndex: 'Result',
        key: 'Result',
    },
    {
        title: 'Unit',
        dataIndex: 'Unit',
        key: 'Unit',
    },
    {
        title: 'Max',
        dataIndex: 'Max',
        key: 'Max',
    },
    {
        title: 'Min',
        dataIndex: 'Min',
        key: 'Min',
    },
    {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
    },
]